export enum Glyphs {
  "$1" = "!",
  "$2" = "?",
  "$3" = "!!",
  "$4" = "??",
  "$5" = "!?",
  "$6" = "?!",
  "$7" = "□",
  "$8" = "□",
  "$9" = "□",
  "$10" = "=",
  "$11" = "=",
  "$12" = "=",
  "$13" = "∞",
  "$14" = "⩲",
  "$15" = "⩱",
  "$16" = "±",
  "$17" = "∓",
  "$18" = "+−",
  "$19" = "−+",
  "$22" = "⨀",
  "$23" = "⨀",
  "$26" = "○",
  "$27" = "○",
  "$32" = "⟳",
  "$33" = "⟳",
  "$36" = "↑",
  "$37" = "↑",
  "$40" = "→",
  "$41" = "→",
  "$44" = "⯹",
  "$45" = "⯹",
}

export const GLYPHS_LIST = [
  { value: "$1", label: "!" },
  { value: "$2", label: "?" },
  { value: "$3", label: "!!" },
  { value: "$4", label: "??" },
  { value: "$5", label: "!?" },
  { value: "$6", label: "?!" },
  { value: "$7", label: "□" },
  { value: "$10", label: "=" },
  { value: "$13", label: "∞" },
  { value: "$14", label: "⩲" },
  { value: "$15", label: "⩱" },
  { value: "$16", label: "±" },
  { value: "$17", label: "∓" },
  { value: "$18", label: "+−" },
  { value: "$19", label: "−+" },
  { value: "$22", label: "⨀" },
  { value: "$26", label: "○" },
  { value: "$32", label: "⟳" },
  { value: "$36", label: "↑" },
  { value: "$40", label: "→" },
  { value: "$44", label: "⯹" },
];
