import React from "react";
import { GetOpeningPractice } from "../../../common/types/get-opening-practice";
import { GetVariantPractice } from "../../../common/types/get-variant-practice";
import { Orientation } from "../../../common/types/orientation";
import styles from "./menu-opening-practice.module.scss";
import MenuVariantPractice from "./menu-variant-practice";

type MenuOpeningPracticeProps = {
  opening: GetOpeningPractice;
  isOpened: boolean;
  toggleOpening: () => void;
  updateMenu: () => void;
};

const MenuOpeningPractice = (props: MenuOpeningPracticeProps) => {
  const isWhite = props.opening.side === Orientation.WHITE;

  const countForRepeating = props.opening.variants.reduce((acc, variant) => {
    return acc + variant.practice_count;
  }, 0);

  return (
    <div className={styles.item}>
      <div className={styles.item_info} onClick={props.toggleOpening}>
        <div className={styles.item_name}>
          <span
            className={`bi bi-${
              isWhite ? "circle-fill" : "circle"
            } ${styles.opening_side}`}
            title={isWhite ? "За белых" : "За черных"}
          ></span>
          <div className={styles.opening_name}>
            <span>{props.opening.name}</span>
          </div>
          <span>({countForRepeating})</span>
        </div>
        <span
          className={`bi bi-${props.isOpened ? "chevron-down" : "chevron-left"}`}
          title={props.isOpened ? "Закрыть варианты" : "Открыть варианты"}
        ></span>
      </div>
      {props.isOpened && (
        <div className={styles.variant_items}>
          {props.opening.variants.map((variant: GetVariantPractice) => {
            return (
              <MenuVariantPractice
                key={`variant-item-${variant.id}`}
                variant={variant}
                updateMenu={props.updateMenu}
              ></MenuVariantPractice>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuOpeningPractice;
