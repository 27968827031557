import { createEvent, createStore } from "effector";
import { Offcanvas } from "bootstrap";

export const showMenu = createEvent();
export const hideMenu = createEvent();

const $menu = createStore<Offcanvas | null>(null);

$menu.on(showMenu, () => {
  const offcanvas = document.getElementById("menu");
  if (!offcanvas) {
    return;
  }

  const bsOffcanvas = new Offcanvas(offcanvas);

  bsOffcanvas.show();

  return bsOffcanvas;
});

$menu.on(hideMenu, (state) => {
  state?.hide();
  return null;
});
