import { getAxiosInstance } from "../axios-instance";

export async function deleteTypical(typicalId: number): Promise<void> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .delete(`/api/v1/typicals/${typicalId}`)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}
