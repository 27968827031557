import React, { useState } from "react";
import Modal from ".";
import { GetTypical } from "../../common/types/get-typical";
import styles from "./modal.module.scss";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import { editTypical } from "../../common/api/typical/edit-typical";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditTypicalModalProps = {
  id: string;
  typical: GetTypical;
  onClose: () => void;
};

const EditTypicalModal = (props: EditTypicalModalProps) => {
  const [variantName, setVariantName] = useState(props.typical.name);
  const [variantPgn, setVariantPgn] = useState(props.typical.pgn);

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Типовая позиция вариант - ${props.typical.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        editTypical(props.typical.id, variantName, variantPgn)
          .then(() => {
            hideModal(props.id);
            props.onClose();
            showSuccessAlert("Типовой вариант успешно изменен");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось изменить типовой вариант");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название типового варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={variantName}
          onChange={(value) => setVariantName(String(value))}
          placeholder="Введите название типовой позиции"
        ></Input>
        <label>Pgn типового варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={variantPgn}
          onChange={(value) => setVariantPgn(String(value))}
          placeholder="Введите pgn типовой позиции"
        ></Input>
      </div>
    </Modal>
  );
};

export default EditTypicalModal;
