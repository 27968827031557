import { GetOpeningAnalyze } from "../../types/get-opening-analyze";
import { getAxiosInstance } from "../axios-instance";

export async function editOpening(
  openingId: number,
  name: string,
): Promise<GetOpeningAnalyze> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .put<{ opening: GetOpeningAnalyze }>(`/api/v1/openings/${openingId}`, {
        name,
      })
      .then((data) => resolve(data.data.opening))
      .catch((error) => reject(error));
  });
}
