import { sample, createEvent, createStore } from "effector";
import { $fetchUserInfoFx } from "../common/api/user/get-user-info";
import { GetUserInfo } from "../common/types/get-user-info";
import { hideLoading, showLoading } from "./main";
import { $loginFx } from "../common/api/auth/login";
import { $logoutFx } from "../common/api/auth/logout";
import { Role } from "../common/types/role";
import { UserState } from "../common/types/user-state";
import { $updateUserInfoFx } from "../common/api/user/update-user-info";

export const login = createEvent<{ login: string; password: string }>();

sample({
  clock: login,
  target: [$loginFx, showLoading],
});

export const logout = createEvent();

sample({
  clock: logout,
  target: [$logoutFx, showLoading],
});

export const getUserInfo = createEvent();
export const $userInfo = createStore<GetUserInfo | null>(null);

const setUserInfo = createEvent<GetUserInfo | null>();
$userInfo.on(setUserInfo, (_, user) => user);

sample({
  clock: getUserInfo,
  target: [$fetchUserInfoFx],
});

sample({
  clock: $fetchUserInfoFx.doneData,
  target: [setUserInfo],
});

sample({
  clock: $fetchUserInfoFx.fail,
  fn: () => null,
  target: [setUserInfo],
});

export const updateUserInfo = createEvent<UserState>();

sample({
  clock: updateUserInfo,
  fn: (data) => ({
    section: data.section,
    variant_id: data.variant_id || -1,
    move_access_key: data.move_access_key || [],
  }),
  target: $updateUserInfoFx,
});

sample({
  clock: $updateUserInfoFx.done,
  target: [getUserInfo],
});

export const checkPermission = (permission: Role) => {
  return $userInfo.getState()?.permissions.includes(permission);
};
