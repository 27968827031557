import { createEvent, createStore } from "effector";
import { Modal } from "bootstrap";
export const showModal = createEvent<string>();
export const hideModal = createEvent<string>();

const $modal = createStore<Record<string, Modal>>({});

$modal.on(showModal, (state: Record<string, Modal>, id: string) => {
  const modal = document.getElementById(id);
  if (!modal) {
    return;
  }

  const bsModal = new Modal(modal);
  bsModal.show();

  return { ...state, [id]: bsModal };
});

$modal.on(hideModal, (state: Record<string, Modal>, id: string) => {
  state[id]?.hide();
  delete state[id];

  return state;
});
