import React, { useState } from "react";
import Modal from ".";
import { GetVariantAnalyze } from "../../common/types/get-variant-analyze";
import styles from "./modal.module.scss";
import { editVariant } from "../../common/api/variants/edit-variant";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditVariantModalProps = {
  id: string;
  variant: GetVariantAnalyze;
  onClose: () => void;
};

const EditVariantModal = (props: EditVariantModalProps) => {
  const [variantName, setVariantName] = useState(props.variant.name);

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Изменить вариант - ${props.variant.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        editVariant(props.variant.id, variantName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Вариант успешно обновлен");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось обновить вариант");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={variantName}
          onChange={(value) => setVariantName(String(value))}
          placeholder="Введите название позиции"
        ></Input>
      </div>
    </Modal>
  );
};

export default EditVariantModal;
