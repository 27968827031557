import React from "react";
import { useNavigate } from "react-router-dom";
import { GetCount } from "../../../common/types/get-count";
import { Role } from "../../../common/types/role";
import { Sections } from "../../../common/types/sections";
import DeleteCountModal from "../../modal/delete-count.modal";
import EditCountModal from "../../modal/edit-count.modal";
import styles from "./menu-count-item.module.scss";
import { checkPermission, updateUserInfo } from "../../../stores/user";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

type MenuCountItemProps = {
  count: GetCount;
  updateMenu: () => void;
};

const MenuCountItem = (props: MenuCountItemProps) => {
  const navigate = useNavigate();

  return (
    <>
      <DeleteCountModal
        id={`delete-count-modal-${props.count.id}`}
        count={props.count}
        onClose={props.updateMenu}
      ></DeleteCountModal>
      <EditCountModal
        id={`edit-count-modal-${props.count.id}`}
        count={props.count}
        onClose={props.updateMenu}
      ></EditCountModal>
      <div className={styles.item}>
        <div className={styles.item_info}>
          <div className={styles.item_name}>
            <span
              onClick={() => {
                updateUserInfo({
                  section: Sections.COUNT,
                  variant_id: props.count.id,
                });
                hideMenu();
                navigate(`/count/${props.count.id}`);
              }}
            >
              {props.count.name}
            </span>
          </div>
        </div>
        {checkPermission(Role.TECHNICAL_CHANGE) && (
          <div>
            <span
              id={styles.change_item_button}
              className="bi bi-file-earmark-text"
              title="Изменить партию"
              onClick={() => {
                showModal(`edit-count-modal-${props.count.id}`);
              }}
            ></span>
            <span
              id={styles.delete_item_button}
              className="bi bi-file-earmark-x"
              title="Удалить партию"
              onClick={() => {
                showModal(`delete-count-modal-${props.count.id}`);
              }}
            ></span>
          </div>
        )}
      </div>
    </>
  );
};

export default MenuCountItem;
