import styles from "./analyze-moves.module.scss";
import React from "react";
import { cloneDeep, isEqual } from "lodash";
import AnalyzeItem from "../items/analyze-item";
import { MoveTree } from "../../common/utils/pgn";

type AnalyzeMovesProps = {
  moves: MoveTree[][];
  activeKey: number[];
  accessKey: number[];
  onClick: (newActiveMove: number[]) => void;
  onComment: (activeMove: number[], comment: string) => void;
  onUp: (activeMove: number[]) => void;
  onRemove: (activeMove: number[]) => void;
  onTag: (activeMove: number[]) => void;
};

const AnalyzeMoves = (props: AnalyzeMovesProps) => {
  return (
    <div
      className={
        props.accessKey.length === 0 ? styles.first_lines : styles.lines
      }
    >
      {props.moves.map((tree: MoveTree[], movesTreeId: number) => {
        const accessKey = cloneDeep(props.accessKey);
        if (accessKey.length > 0) {
          accessKey.push(movesTreeId);
          accessKey.push(-1);
        }

        return (
          <div key={movesTreeId} className={styles.line}>
            {tree.map((move: MoveTree, moveId: number) => {
              const rawLastAccessKey = accessKey.pop();
              const lastAccessKey =
                rawLastAccessKey === undefined ? -1 : rawLastAccessKey;
              accessKey.push(lastAccessKey + 1);
              const clonedAccessKey = [...accessKey];

              const isAfterComment =
                moveId !== 0 && !!tree[moveId - 1].move.comment;

              return (
                <div className={styles.empty_wrapper} key={moveId}>
                  <AnalyzeItem
                    accessKey={clonedAccessKey}
                    move={move.move}
                    isMainLine={props.accessKey.length === 0}
                    isFirstLineMove={moveId === 0}
                    isAfterComment={isAfterComment}
                    isActive={isEqual(clonedAccessKey, props.activeKey)}
                    onClick={() => props.onClick(clonedAccessKey)}
                    onComment={(comment: string) =>
                      props.onComment(clonedAccessKey, comment)
                    }
                    onTag={() => {
                      props.onTag(clonedAccessKey);
                    }}
                    onUp={() => props.onUp(clonedAccessKey)}
                    onRemove={() => props.onRemove(clonedAccessKey)}
                  ></AnalyzeItem>
                  {move.variants.length > 0 && (
                    <AnalyzeMoves
                      moves={move.variants}
                      accessKey={clonedAccessKey}
                      activeKey={props.activeKey}
                      onClick={props.onClick}
                      onComment={props.onComment}
                      onTag={props.onTag}
                      onUp={props.onUp}
                      onRemove={props.onRemove}
                    ></AnalyzeMoves>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AnalyzeMoves;
