import React, { useState } from "react";
import Modal from ".";
import { GetOpeningAnalyze } from "../../common/types/get-opening-analyze";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import styles from "./modal.module.scss";
import { editOpening } from "../../common/api/openings/edit-opening";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditOpeningModalProps = {
  id: string;
  opening: GetOpeningAnalyze;
  onClose: () => void;
};

const EditOpeningModal = (props: EditOpeningModalProps) => {
  const [openingName, setOpeningName] = useState(props.opening.name);

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Изменить дебют - ${props.opening.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        editOpening(props.opening.id, openingName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Дебют успешно обновлен");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось обновить дебют");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название дебюта</label>
        <Input
          type={InputTypes.TEXT}
          value={openingName}
          onChange={(value) => {
            setOpeningName(String(value));
          }}
          placeholder="Введите название дебюта"
        ></Input>
      </div>
    </Modal>
  );
};

export default EditOpeningModal;
