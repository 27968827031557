import { GetTechnicalGroup } from "../../types/get-technical-group";
import { getAxiosInstance } from "../axios-instance";

export async function getTechnicalGroup(): Promise<GetTechnicalGroup[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ technical_groups: GetTechnicalGroup[] }>(`/api/v1/technical-group`)
      .then((data) => resolve(data.data.technical_groups))
      .catch((error) => reject(error));
  });
}
