import styles from "./wrapper.module.scss";
import React, { useState, useEffect } from "react";

type WrapperProps = {
  topmenu: React.ReactNode;
  menu?: React.ReactNode;
  inRef?: any;

  board: React.ReactNode;
  buttons?: React.ReactNode;
  movesHeader?: React.ReactNode;
  movesPgn: React.ReactNode;
  footer?: React.ReactNode;

  isMovesOpened: boolean;
  isFooterOpened: boolean;

  movesTitle: string;
  footerTitle: string;

  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const Wrapper = (props: WrapperProps) => {
  const [isMovesOpened, setIsMovesOpened] = useState(props.isMovesOpened);
  const [isFooterOpened, setIsFooterOpened] = useState(props.isFooterOpened);

  useEffect(() => {
    if (props.inRef?.current) {
      const el = props.inRef.current as any;
      el.focus();
    }
  }, [props.inRef]);

  return (
    <>
      {props.topmenu}
      {props.menu}
      <div
        id={styles.container}
        tabIndex={0}
        autoFocus={true}
        ref={props.inRef}
        onKeyDown={(event) =>
          props.onKeyDown ? props.onKeyDown(event) : undefined
        }
      >
        <div id={styles.board_moves}>
          <div id={styles.board_buttons}>
            <div id={styles.board}>{props.board}</div>
            <div id={styles.buttons}>{props.buttons}</div>
          </div>
          {window.innerWidth >= 768 && (
            <div id={styles.moves}>
              {props.movesHeader && (
                <div id={styles.moves_header}>{props.movesHeader}</div>
              )}
              <div id={styles.moves_pgn}>{props.movesPgn}</div>
            </div>
          )}
        </div>
        <div
          id={styles.moves_accordion}
          onClick={() => setIsMovesOpened((state) => !state)}
        >
          <span>{props.movesTitle}</span>
          <span
            className={`bi bi-${
              isMovesOpened ? "chevron-down" : "chevron-left"
            }`}
          ></span>
        </div>
        {window.innerWidth < 768 && isMovesOpened && (
          <div id={styles.moves}>
            {props.movesHeader && (
              <div id={styles.moves_header}>{props.movesHeader}</div>
            )}
            <div id={styles.moves_pgn}>{props.movesPgn}</div>
          </div>
        )}
        {props.footer && (
          <div
            id={styles.footer_accordion}
            onClick={() => setIsFooterOpened((state) => !state)}
          >
            <span>{props.footerTitle}</span>
            <span
              className={`bi bi-${
                isFooterOpened ? "chevron-down" : "chevron-left"
              }`}
            ></span>
          </div>
        )}
        {props.footer && (window.innerWidth >= 768 || isFooterOpened) && (
          <div id={styles.footer}>{props.footer}</div>
        )}
      </div>
    </>
  );
};

export default Wrapper;
