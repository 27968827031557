import { GetVariantAnalyze } from "../../types/get-variant-analyze";
import { getAxiosInstance } from "../axios-instance";

export async function getVariant(
  variantId: number,
): Promise<GetVariantAnalyze> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ variant: GetVariantAnalyze }>(`/api/v1/variants/${variantId}`)
      .then((data) => resolve(data.data.variant))
      .catch((error) => reject(error));
  });
}
