import React, { useState, useEffect } from "react";
import { getTechnicalGroup } from "../../../common/api/technical-group/get-technical-group";
import { GetTechnicalGroup } from "../../../common/types/get-technical-group";
import CreateTechnicalGroupModal from "../../modal/create-group.modal";
import MenuTechnicalGroup from "./menu-technical-group";
import styles from "./menu-technical.module.scss";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

const MenuTechnical = () => {
  const [groups, setGroups] = useState<GetTechnicalGroup[]>([]);
  const [openedGroupId, setOpenedGroupId] = useState<number>(0);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    getTechnicalGroup()
      .then((groups) => {
        setGroups(groups);
      })
      .catch(() => undefined);
  };

  return (
    <>
      <CreateTechnicalGroupModal
        id="create-group-modal"
        onClose={() => getGroups()}
      ></CreateTechnicalGroupModal>
      <div id="menu" className={`offcanvas offcanvas-end`}>
        <div id="offcanvas-header" className={styles.offcanvas_header}>
          <span id="menu-title">Меню</span>
          <div>
            <span
              id={styles.create_group_button}
              className="bi bi-file-earmark-plus"
              title="Создать группу"
              onClick={() => {
                showModal("create-group-modal");
              }}
            ></span>
            <span
              id={styles.exit_button}
              className="bi bi-arrow-right"
              title="Выход из меню"
              onClick={() => {
                hideMenu();
              }}
            ></span>
          </div>
        </div>
        <div id="offcanvas-body" className={styles.offcanvas_body}>
          {groups.map((group: GetTechnicalGroup) => {
            return (
              <MenuTechnicalGroup
                key={`group-item-${group.id}`}
                group={group}
                isOpened={group.id === openedGroupId}
                toggleGroup={() =>
                  setOpenedGroupId(openedGroupId === group.id ? 0 : group.id)
                }
                updateMenu={() => getGroups()}
              ></MenuTechnicalGroup>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuTechnical;
