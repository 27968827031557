import { createEffect } from "effector";
import { GetTokens } from "../../types/get-tokens";
import { getAxiosInstance } from "../axios-instance";
import { showDangerAlert } from "../../../stores/alert";
import { saveAuthToken, saveRefreshToken } from "../../utils/local-storage";

export const $loginFx = createEffect<
  { login: string; password: string },
  void
>();

$loginFx.use(async (data) => {
  await login(data.login, data.password);
});

async function login(login: string, password: string): Promise<void> {
  try {
    const { data } = await getAxiosInstance().post<{ auth: GetTokens }>(
      `/api/v1/auth/login`,
      {
        login,
        password,
      },
    );

    saveAuthToken(data.auth.auth_token);
    saveRefreshToken(data.auth.refresh_token);

    window.location.reload();
  } catch (error) {
    showDangerAlert("Не удалось авторизоваться");
  }
}
