import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@chrisoakman/chessboardjs/dist/chessboard-1.0.0.js";
import App from "./app";
import { getUserInfo } from "./stores/user";

getUserInfo();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);
