import React from "react";
import Modal from ".";
import Textarea from "../shared/textarea/textarea";
import styles from "./modal.module.scss";
import { hideModal } from "../../stores/modal";

type CommentModalProps = {
  id: string;
  comment: string;
  onComment: (comment: string) => void;
};

const CommentModal = (props: CommentModalProps) => {
  const [comment, setComment] = React.useState(props.comment);

  React.useEffect(() => {
    setComment(props.comment);
  }, [props.comment]);

  return (
    <Modal
      id={props.id}
      cancelName="Не добавлять"
      okName="Добавить"
      title={`Добавление/изменение комментария`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        props.onComment(comment);
        hideModal(props.id);
      }}
    >
      <div className={styles.input_container}>
        <label>Комментарий</label>
        <Textarea
          value={comment}
          onChange={(value) => setComment(value)}
          placeholder="Введите комментарий"
        ></Textarea>
      </div>
    </Modal>
  );
};

export default CommentModal;
