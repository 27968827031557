import React, { useState, useEffect } from "react";
import { Orientation } from "../../common/types/orientation";
import styles from "./board.module.scss";
import { ChessHelper } from "../../common/utils/chess";
import SquareComponent from "./square/square";
import {
  Color,
  DEFAULT_POSITION,
  Piece,
  PieceSymbol,
  Square,
  SQUARES,
} from "chess.js";
import { Board as BoardModel } from "./types/board";
import { cloneDeep } from "lodash";
import { Turn } from "./types/turn";
import { isMobile } from "detect-touch-device";

const EMPTY_POSITION = "8/8/8/8/8/8/8/8 w - - 0 1";

type EditBoardProps = {
  id: string;
  orientation: Orientation;
  onChangeFen: (fen: string) => void;
};

const EditBoard = (props: EditBoardProps) => {
  const [squares, setSquares] = useState<Square[]>(cloneDeep(SQUARES));
  const [orientation, setOrientation] = useState<Orientation>(
    props.orientation,
  );
  const [position, setPosition] = useState<string>(DEFAULT_POSITION);
  const [board, setBoard] = useState<BoardModel>([]);
  const [floatFigure, setFloatFigure] = useState<Piece | undefined>(undefined);
  const [firstMove, setFirstMove] = useState<Turn>(Turn.W);
  const [currentFigure, setCurrentFigure] = useState<string | undefined>(
    undefined,
  );
  const [from, setFrom] = useState<Square | undefined>(undefined);
  const [to, setTo] = useState<Square | undefined>(undefined);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  const rightExtra = ["wK", "wQ", "wR", "wB", "wN", "wP"];
  const bottomExtra = ["bK", "bQ", "bR", "bB", "bN", "bP"];

  const boardEl = document.getElementById(props.id);
  const boardWidth = boardEl?.offsetWidth || 0;
  const boardHeight = boardEl?.offsetHeight || 0;
  const boardMarginTop = 60;
  const boardMarginLeft = 10;

  useEffect(() => {
    updatePosition();
  }, []);

  const onClick = (square: Square) => {
    if (!currentFigure) {
      return;
    }

    const chess = new ChessHelper();
    chess.init(position);

    if (currentFigure === "bucket") {
      chess.remove(square);
    } else {
      const pieceColor = currentFigure[0] as Color;
      const pieceType = currentFigure[1].toLowerCase() as PieceSymbol;
      chess.put(square, pieceType, pieceColor);
    }

    if (!chess.validateFen(chess.getFen())) {
      return;
    }

    sendFen(chess);
  };

  const handleMouseMove = (event: MouseEvent) => {
    setX(event.clientX - boardWidth / (2 * 9) - boardMarginLeft);
    setY(event.clientY - boardHeight / (2 * 9) - boardMarginTop);
  };

  const onStartDragProcess = (
    square: Square,
    clientX: number,
    clientY: number,
  ) => {
    if (from) {
      return;
    }

    window.addEventListener("mousemove", handleMouseMove);

    const chess = new ChessHelper();
    chess.init(position);

    setFrom(square);
    setFloatFigure(chess.getPiece(square));
    setX(clientX - boardWidth / (2 * 9) - boardMarginLeft);
    setY(clientY - boardHeight / (2 * 9) - boardMarginTop);
  };

  const onMouseUp = () => {
    if (!from) {
      return;
    }

    const currentX = x + boardWidth / (2 * 9);
    const currentY = y + boardHeight / (2 * 9);
    const rows = ["8", "7", "6", "5", "4", "3", "2", "1"];
    const cols = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (orientation === Orientation.BLACK) {
      rows.reverse();
      cols.reverse();
    }

    const row = rows[Math.floor(currentY / (boardHeight / 9))];
    const col = cols[Math.floor(currentX / (boardWidth / 9))];
    const square = `${col}${row}` as Square;

    if (from === square) {
      finish();
      return;
    }

    setTo(square);

    window.removeEventListener("mousemove", handleMouseMove);

    const chess = new ChessHelper();
    chess.init(position);

    if (floatFigure) {
      chess.remove(from);
      chess.put(square, floatFigure.type, floatFigure.color);
    }

    if (!chess.validateFen(chess.getFen())) {
      finish();
      return;
    }

    sendFen(chess);
  };

  const finish = () => {
    setFrom(undefined);
    setTo(undefined);
    setFloatFigure(undefined);
  };

  const sendFen = (chess: ChessHelper) => {
    const board = cloneDeep(chess.board());
    if (props.orientation === Orientation.BLACK) {
      board.forEach((row) => row.reverse());
      board.reverse();
    }

    setBoard(board);
    setPosition(chess.getFen());

    const fen = chess.getFen();
    const fenArr = fen.split(" ");
    fenArr[1] = firstMove;
    const patchedFen = fenArr.join(" ");
    if (chess.validateFen(patchedFen)) {
      props.onChangeFen(patchedFen);
    }
    finish();
  };

  const updatePosition = () => {
    const chess = new ChessHelper();
    chess.init(position);

    const board = cloneDeep(chess.board());
    if (props.orientation === Orientation.BLACK) {
      board.forEach((row) => row.reverse());
      board.reverse();
    }

    const squares = cloneDeep(SQUARES);
    if (props.orientation === Orientation.BLACK) {
      squares.reverse();
    }

    setSquares(squares);
    setBoard(board);
    setOrientation(props.orientation);
  };

  return (
    <>
      <div id={props.id} className={styles.board}>
        {board.map((row, indexRow) => {
          return (
            <div key={indexRow} className={styles.row}>
              {row.map((figure, indexSquare) => {
                const square = squares[indexRow * 8 + indexSquare];

                return (
                  <div key={indexSquare} className={styles.square}>
                    <SquareComponent
                      square={square}
                      editable={true}
                      figure={floatFigure && square === from ? null : figure}
                      dummy={false}
                      active={false}
                      movable={!currentFigure && !isMobile}
                      availableForMove={false}
                      onStartDragProcess={(x: number, y: number) =>
                        onStartDragProcess(square, x, y)
                      }
                      onClick={() => onClick(square)}
                    ></SquareComponent>
                  </div>
                );
              })}
              {indexRow < 6 && (
                <div key={`extra-right-${indexRow}`} className={styles.square}>
                  <img
                    key="bucket"
                    draggable={false}
                    src={`${process.env.PUBLIC_URL}/chesspieces/${rightExtra[indexRow]}.svg`}
                    className={`${styles.extra} ${currentFigure === rightExtra[indexRow] ? styles.extra_active : ""}`}
                    onClick={() => {
                      setCurrentFigure(rightExtra[indexRow]);
                    }}
                  />
                </div>
              )}
              {indexRow === 6 && (
                <div key={`extra-right-bucket`} className={styles.square}>
                  <img
                    key="bucket"
                    draggable={false}
                    src={`${process.env.PUBLIC_URL}/chesspieces/clear.svg`}
                    className={`${styles.extra} ${currentFigure === "bucket" ? styles.extra_active : ""}`}
                    title="Удалить фигуру"
                    onClick={() => {
                      setCurrentFigure("bucket");
                    }}
                  />
                </div>
              )}
              {indexRow === 7 && (
                <div key={`extra-right-first-move`} className={styles.square}>
                  <img
                    key="first-move"
                    src={
                      firstMove === Turn.W
                        ? `${process.env.PUBLIC_URL}/chesspieces/white-turn.svg`
                        : `${process.env.PUBLIC_URL}/chesspieces/black-turn.svg`
                    }
                    title="Первый ход"
                    className={styles.extra}
                    draggable={false}
                    onClick={() => {
                      const newFirstMove =
                        firstMove === Turn.W ? Turn.B : Turn.W;
                      const chess = new ChessHelper();
                      chess.init(position);
                      const fenArr = position.split(" ");
                      fenArr[1] = newFirstMove;
                      const patchedFen = fenArr.join(" ");
                      if (chess.validateFen(patchedFen)) {
                        props.onChangeFen(patchedFen);
                      }

                      setFirstMove(newFirstMove);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
        <div key={`extra-bottom`} className={styles.row}>
          {bottomExtra.map((el) => {
            return (
              <div key={`extra-bottom-${el}`} className={styles.square}>
                <img
                  draggable={false}
                  src={`${process.env.PUBLIC_URL}/chesspieces/${el}.svg`}
                  className={`${styles.extra} ${currentFigure === el ? styles.extra_active : ""}`}
                  onClick={() => {
                    setCurrentFigure(el);
                  }}
                />
              </div>
            );
          })}
          <div key={`extra-bottom-clear`} className={styles.square}>
            <img
              key="clear"
              src={`${process.env.PUBLIC_URL}/chesspieces/bucket.svg`}
              title="Очистить позицию"
              className={styles.extra}
              draggable={false}
              onClick={() => {
                const chess = new ChessHelper();
                chess.loadFenWithoutValidation(EMPTY_POSITION);
                setBoard(chess.board());
                setPosition(EMPTY_POSITION);
              }}
            />
          </div>
          <div key={`extra-bottom-start`} className={styles.square}>
            <img
              key="start"
              src={`${process.env.PUBLIC_URL}/chesspieces/start.svg`}
              title="Стартовая позиция"
              className={styles.extra}
              draggable={false}
              onClick={() => {
                const chess = new ChessHelper();
                chess.loadFenWithoutValidation(DEFAULT_POSITION);
                setBoard(chess.board());
                setPosition(DEFAULT_POSITION);
              }}
            />
          </div>
          <div key={`extra-bottom-empty}`} className={styles.square}>
            <img
              key="start"
              src={`${process.env.PUBLIC_URL}/chesspieces/eraser.svg`}
              title="Убрать инструмент"
              className={styles.extra}
              draggable={false}
              onClick={() => {
                setCurrentFigure(undefined);
              }}
            />
          </div>
        </div>
      </div>

      {floatFigure && (
        <img
          id={styles.float_figure_edit}
          draggable={false}
          onMouseUp={() => onMouseUp()}
          style={{ top: y, left: x }}
          src={`${process.env.PUBLIC_URL}/chesspieces/${floatFigure.color}${floatFigure.type.toUpperCase()}.svg`}
        />
      )}
    </>
  );
};

export default EditBoard;
