import styles from "./variant-typicals.module.scss";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MenuTypical from "../menu/typical/menu-typical";
import { GetTypical } from "../../common/types/get-typical";
import { getTypicals } from "../../common/api/typical/get-typical-list";
import { Sections } from "../../common/types/sections";
import EditTypicalModal from "../modal/edit-typical.modal";
import DeleteTypicalModal from "../modal/delete-typical.modal";
import DummyBoard from "../board/dummy-board";
import { updateUserInfo } from "../../stores/user";
import { showModal } from "../../stores/modal";

const VariantTypicals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [typicals, setTypicals] = useState<GetTypical[]>([]);

  useEffect(() => {
    getTypicalList();
  }, []);

  const getTypicalList = () => {
    const urlArray = location.pathname.split("/");
    const variantId = Number(urlArray[urlArray.length - 1]);

    if (!variantId || variantId === -1) {
      return;
    }

    getTypicals(variantId)
      .then((typicals) => {
        setTypicals(typicals);
      })
      .catch(() => undefined);
  };

  return (
    <>
      <MenuTypical></MenuTypical>
      <div id={styles.container}>
        {typicals.map((typical) => {
          return (
            <div key={typical.id} className={styles.typical}>
              <EditTypicalModal
                id={`edit-typical-modal-${typical.id}`}
                typical={typical}
                onClose={() => {
                  getTypicalList();
                }}
              ></EditTypicalModal>
              <DeleteTypicalModal
                id={`delete-typical-modal-${typical.id}`}
                typical={typical}
                onClose={() => {
                  getTypicalList();
                }}
              ></DeleteTypicalModal>
              <div className={styles.typical_header}>
                <span
                  className={styles.typical_header_name}
                  onClick={() => {
                    updateUserInfo({
                      section: Sections.TYPICAL,
                      variant_id: typical.id,
                    });
                    navigate(`/typical/${typical.id}`);
                  }}
                >
                  {typical.name}
                </span>
                <span
                  id={styles.edit_typical}
                  className="bi bi-file-earmark-text"
                  title="Изменить позицию"
                  onClick={() => {
                    showModal(`edit-typical-modal-${typical.id}`);
                  }}
                ></span>
                <span
                  id={styles.delete_typical}
                  className="bi bi-file-earmark-x"
                  title="Удалить позицию"
                  onClick={() => {
                    showModal(`delete-typical-modal-${typical.id}`);
                  }}
                ></span>
              </div>
              <div className={styles.board_wrapper}>
                <DummyBoard
                  id={`chessboard-${typical.id}`}
                  orientation={typical.side}
                  position={typical.pgn}
                ></DummyBoard>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VariantTypicals;
