import React from "react";
import styles from "./button.module.scss";
import { ButtonSize } from "./types/size";

type ButtonProps = {
  title: string;
  text: string;
  size: ButtonSize;
  onClick: () => void;
};

const Button = (props: ButtonProps) => {
  const getStyle = () => {
    switch (props.size) {
      case ButtonSize.ORDINARY:
        return styles.ordinary;
      case ButtonSize.SMALL:
        return styles.small;
    }
  };

  return (
    <button
      type="submit"
      title={props.title}
      className={`btn ${getStyle()}`}
      onClick={() => props.onClick()}
    >
      {props.text}
    </button>
  );
};

export default Button;
