import React from "react";
import { useNavigate } from "react-router-dom";
import { GetTechnical } from "../../../common/types/get-technical";
import { Role } from "../../../common/types/role";
import { Sections } from "../../../common/types/sections";
import { TechnicalResult } from "../../../common/types/technical-result";
import DeleteTechnicalModal from "../../modal/delete-technical.modal";
import EditTechnicalModal from "../../modal/edit-technical.modal";
import styles from "./menu-technical-item.module.scss";
import { checkPermission, updateUserInfo } from "../../../stores/user";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

type MenuTechnicalItemProps = {
  technical: GetTechnical;
  updateMenu: () => void;
};

const MenuTechnicalItem = (props: MenuTechnicalItemProps) => {
  const navigate = useNavigate();
  const isWin = props.technical.result === TechnicalResult.WIN;

  return (
    <>
      <DeleteTechnicalModal
        id={`delete-technical-modal-${props.technical.id}`}
        technical={props.technical}
        onClose={props.updateMenu}
      ></DeleteTechnicalModal>
      <EditTechnicalModal
        id={`edit-technical-modal-${props.technical.id}`}
        technical={props.technical}
        onClose={props.updateMenu}
      ></EditTechnicalModal>
      <div className={styles.item}>
        <div className={styles.item_info}>
          <div className={styles.item_name}>
            <span
              className={`bi bi-${isWin ? "circle-fill" : "circle-half"} ${styles.item_result}`}
              title={isWin ? "На выигрыш" : "На ничью"}
            ></span>
            <span
              onClick={() => {
                updateUserInfo({
                  section: Sections.TECHNICAL,
                  variant_id: props.technical.id,
                });
                hideMenu();
                navigate(`/technical/${props.technical.id}`);
              }}
            >
              {props.technical.name}
            </span>
          </div>
        </div>
        {checkPermission(Role.TECHNICAL_CHANGE) && (
          <div>
            <span
              id={styles.change_item_button}
              className="bi bi-file-earmark-text"
              title="Изменить позицию"
              onClick={() => {
                showModal(`edit-technical-modal-${props.technical.id}`);
              }}
            ></span>
            <span
              id={styles.delete_item_button}
              className="bi bi-file-earmark-x"
              title="Удалить позицию"
              onClick={() => {
                showModal(`delete-technical-modal-${props.technical.id}`);
              }}
            ></span>
          </div>
        )}
      </div>
    </>
  );
};

export default MenuTechnicalItem;
