import styles from "./auth.module.scss";
import React, { useState, useRef, useEffect } from "react";
import Button from "../shared/button/button";
import { ButtonSize } from "../shared/button/types/size";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import { login } from "../../stores/user";

const Auth = () => {
  const [loginStr, setLogin] = useState("");
  const [passwordStr, setPassword] = useState("");
  const container = useRef(null);

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!["Space", "Enter"].includes(event.code)) {
      return;
    }

    event.preventDefault();

    login({ login: loginStr, password: passwordStr });
  };

  useEffect(() => {
    if (container.current) {
      const el = container.current as any;
      el.focus();
    }
  }, []);

  return (
    <div
      id={styles.container}
      ref={container}
      tabIndex={0}
      autoFocus={true}
      onKeyDown={(event) => eventHandler(event)}
    >
      <form id={styles.form}>
        <div id={styles.auth_login}>
          <label>Логин</label>
          <Input
            type={InputTypes.TEXT}
            value={loginStr}
            placeholder="Введите логин"
            onChange={(value) => setLogin(String(value))}
          ></Input>
        </div>
        <div id={styles.auth_password}>
          <label>Пароль</label>
          <Input
            type={InputTypes.PASSWORD}
            value={passwordStr}
            placeholder="Введите пароль"
            onChange={(value) => setPassword(String(value))}
          ></Input>
        </div>
        <div id={styles.auth_submit}>
          <Button
            title="Войти"
            text="Войти"
            size={ButtonSize.ORDINARY}
            onClick={() => login({ login: loginStr, password: passwordStr })}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default Auth;
