import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useStoreMap } from "effector-react";
import { Sections } from "../../common/types/sections";
import styles from "./topmenu.module.scss";
import { $userInfo, logout, updateUserInfo } from "../../stores/user";
import { showMenu } from "../../stores/menu";

type TopmenuProps = {
  hiddenMenuButton: boolean;
};

const Topmenu = (props: TopmenuProps) => {
  const [activeSection, setActiveSection] = useState<Sections | null>(null);
  const userInfo = useStoreMap($userInfo, (state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlArr = location.pathname.split("/");

    if (
      urlArr.length > 0 &&
      [
        Sections.ANALYZE,
        Sections.POSITION,
        Sections.PRACTICE,
        Sections.COUNT,
        Sections.TECHNICAL,
        Sections.TYPICAL,
      ].includes(urlArr[1] as Sections)
    ) {
      setActiveSection(urlArr[1] as Sections);
    }
  }, []);

  const updateUserState = (section: Sections) => {
    updateUserInfo({ section });
    navigate(`/${section}`);
  };

  return (
    <div id={styles.topmenu}>
      <div id={styles.navbar}>
        {window.innerWidth < 768 && (
          <>
            <span
              className={`bi bi-gear ${styles.navbar_link} ${activeSection === Sections.ANALYZE ? styles.active : ""}`}
              title="Анализ дебютов"
              onClick={() => {
                updateUserState(Sections.ANALYZE);
              }}
            ></span>
            <span
              className={`bi bi-repeat ${styles.navbar_link} ${activeSection === Sections.PRACTICE ? styles.active : ""}`}
              title="Повторение вариантов"
              onClick={() => {
                updateUserState(Sections.PRACTICE);
              }}
            ></span>
            <span
              className={`bi bi-diagram-3 ${styles.navbar_link} ${activeSection === Sections.COUNT ? styles.active : ""}`}
              title="Счёт"
              onClick={() => {
                updateUserState(Sections.COUNT);
              }}
            ></span>
            <span
              className={`bi bi-pencil ${styles.navbar_link} ${activeSection === Sections.POSITION ? styles.active : ""}`}
              title="Позиция"
              onClick={() => {
                updateUserState(Sections.POSITION);
              }}
            ></span>
            <span
              className={`bi bi-puzzle ${styles.navbar_link} ${activeSection === Sections.TECHNICAL ? styles.active : ""}`}
              title="Технические позиции"
              onClick={() => {
                updateUserState(Sections.TECHNICAL);
              }}
            ></span>
            <span
              className={`bi bi-mortarboard ${styles.navbar_link} ${activeSection === Sections.TYPICAL ? styles.active : ""}`}
              title="Типовые позиции"
              onClick={() => {
                updateUserState(Sections.TYPICAL);
              }}
            ></span>
          </>
        )}
        {window.innerWidth >= 768 && (
          <>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.ANALYZE ? styles.active : ""}`}
              title="Анализ дебютов"
              onClick={() => {
                updateUserState(Sections.ANALYZE);
              }}
            >
              Анализ
            </h6>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.PRACTICE ? styles.active : ""}`}
              title="Повторение вариантов"
              onClick={() => {
                updateUserState(Sections.PRACTICE);
              }}
            >
              Пoвторение
            </h6>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.COUNT ? styles.active : ""}`}
              title="Счёт"
              onClick={() => {
                updateUserState(Sections.COUNT);
              }}
            >
              Счёт
            </h6>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.POSITION ? styles.active : ""}`}
              title="Позиция"
              onClick={() => {
                updateUserState(Sections.POSITION);
              }}
            >
              Позиция
            </h6>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.TECHNICAL ? styles.active : ""}`}
              title="Технические позиции"
              onClick={() => {
                updateUserState(Sections.TECHNICAL);
              }}
            >
              Техника
            </h6>
            <h6
              className={`${styles.navbar_link} ${activeSection === Sections.TYPICAL ? styles.active : ""}`}
              title="Типовые позиции"
              onClick={() => {
                updateUserState(Sections.TYPICAL);
              }}
            >
              Миттельшпиль
            </h6>
          </>
        )}
      </div>
      <div id={styles.right_menu}>
        {userInfo?.login && (
          <span id={styles.user_login} title="Пользователь">
            {userInfo.login}
          </span>
        )}
        <span
          id={styles.settings_button}
          className={`bi bi-sliders`}
          title="Настройки"
          onClick={() => {
            navigate("/settings");
          }}
        ></span>
        <span
          id={styles.exit_button}
          className={`bi bi-box-arrow-right`}
          title="Выход из системы"
          onClick={() => logout()}
        ></span>
        {props.hiddenMenuButton ? null : (
          <span
            id={styles.menu_button}
            className={`bi bi-list`}
            title="Меню"
            onClick={() => {
              showMenu();
            }}
          ></span>
        )}
      </div>
    </div>
  );
};

export default Topmenu;
