import { Chess, Color, PieceSymbol, Square } from "chess.js";

export type Move = {
  color: Color;
  from: Square;
  to: Square;
  piece: PieceSymbol;
  promotion: PieceSymbol | null;
  san: string;
  before: string;
  after: string;
  comment: string;
  move_number: number;
  glyphs?: string[];
};

export type MoveTree = {
  move: Move;
  variants: MoveTree[][];
};

export function toTree(pgn: string): MoveTree[] {
  const pgnArray = pgn
    .replaceAll(":)", "*smile*")
    .split(/(\(|\))/)
    .map((el) => el.trim())
    .filter((el) => !!el);

  const newPgnArray: string[] = [];

  for (let i = 0; i < pgnArray.length; i++) {
    let countOpen = pgnArray[i].split("{").length - 1;
    let countClose = pgnArray[i].split("}").length - 1;

    let nextEl = pgnArray[i];

    if (countOpen === countClose) {
      newPgnArray.push(nextEl);
      continue;
    }

    while (countOpen !== countClose) {
      i++;

      if (i >= pgnArray.length) {
        break;
      }

      nextEl += pgnArray[i];

      countOpen += pgnArray[i].split("{").length - 1;
      countClose += pgnArray[i].split("}").length - 1;
    }

    newPgnArray.push(nextEl);
  }

  //console.log(pgnArray, newPgnArray);

  return parseToTree(newPgnArray);
}

export function toPgn(moveTree: MoveTree[]) {
  let pgn = "";
  let ifFirst = true;

  for (const move of moveTree) {
    if (move.move.color === "w") {
      pgn += `${move.move.move_number}.`;
    } else if (ifFirst) {
      pgn += `${move.move.move_number}...`;
    }

    pgn += ` ${move.move.san}`;

    if (move.move.glyphs && move.move.glyphs.length > 0) {
      pgn += ` ${move.move.glyphs.join(" ")}`;
    }

    if (move.move.comment) {
      pgn += ` {${move.move.comment}}`;
    }

    if (move.move.color === "b") {
      pgn += ` `;
    }

    for (const variant of move.variants || []) {
      pgn += `(${toPgn(variant)}) `;
    }

    ifFirst = false;
  }

  return pgn;
}

function parseToTree(pgnArray: string[], pgn = "", skip = 0): MoveTree[] {
  const moveTree: MoveTree[] = [];
  let innerPgn = pgn;
  let innerVariantPgn = pgn;
  let innerSkip = skip;
  let pgnEl = pgnArray.shift();

  while (pgnEl) {
    if (pgnEl === ")") {
      return moveTree;
    }

    if (pgnEl !== "(") {
      innerPgn += " " + pgnEl;
      let restOfPgn = pgnEl;
      const chess = new Chess();
      chess.loadPgn(innerPgn);
      const history = chess.history({ verbose: true });
      const comments = chess.getComments();
      const moves = history.slice(innerSkip);
      innerSkip = history.length;

      innerPgn = chess.pgn();
      chess.undo();
      innerVariantPgn = chess.pgn();
      for (const move of moves) {
        const beforeFenArray = move.before.split(" ");
        const pgnArray = restOfPgn.split(move.san);
        let maybeGlyphs = [];
        const glyphs: string[] = [];
        if (pgnArray.length >= 2) {
          pgnArray.shift();
          maybeGlyphs = pgnArray[0].trim().split(" ");
          for (const glyph of maybeGlyphs) {
            if (!glyph.startsWith("$")) {
              break;
            }
            glyphs.push(glyph);
          }
          restOfPgn = pgnArray.join(move.san);
        }

        moveTree.push({
          move: {
            color: move.color,
            from: move.from,
            to: move.to,
            piece: move.piece,
            promotion: move.promotion || null,
            san: move.san,
            before: move.before,
            after: move.after,
            comment:
              comments.find((comment) => comment.fen === move.after)?.comment ||
              "",
            move_number: Number(beforeFenArray[beforeFenArray.length - 1]) || 0,
            glyphs,
          },
          variants: [],
        });
      }

      pgnEl = pgnArray.shift();
      continue;
    }

    const lastElement = moveTree[moveTree.length - 1];
    const variant = parseToTree(pgnArray, innerVariantPgn, innerSkip - 1);
    lastElement.variants.push(variant);

    pgnEl = pgnArray.shift();
  }

  return moveTree;
}
