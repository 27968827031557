import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Topmenu from "../topmenu/topmenu";
import { Orientation } from "../../common/types/orientation";
import styles from "./count.module.scss";
import Board from "../board/board";
import { DEFAULT_POSITION, Move, Square } from "chess.js";
import Wrapper from "../shared/wrapper/wrapper";
import MenuCount from "../menu/count/menu-count";
import { getCount } from "../../common/api/count/get-count";
import { ChessHelper } from "../../common/utils/chess";
import { GetCount } from "../../common/types/get-count";
import { LastMove } from "../board/types/last-move";
import DummyItem from "../items/dummy-item";

const CountComponent = () => {
  const speechRecognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition)();
  const speechRecognitionList = new (window.SpeechGrammarList ||
    window.webkitSpeechGrammarList)();
  let squarePuzzle: "w" | "b" = "w";
  const whiteRecognitionResult = ["белое", "белые", "белый", "белая"];
  const blackRecognitionResult = [
    "черное",
    "черные",
    "черный",
    "черная",
    "чёрное",
    "чёрные",
    "чёрный",
    "чёрная",
  ];
  const blackSquares = [
    "a1",
    "a3",
    "a5",
    "a7",
    "b2",
    "b4",
    "b6",
    "b8",
    "c1",
    "c3",
    "c5",
    "c7",
    "d2",
    "d4",
    "d6",
    "d8",
    "e1",
    "e3",
    "e5",
    "e7",
    "f2",
    "f4",
    "f6",
    "f8",
    "g1",
    "g3",
    "g5",
    "g7",
    "h2",
    "h4",
    "h6",
    "h8",
  ];
  const location = useLocation();

  const [count, setCount] = useState<GetCount | null>(null);
  const [position, setPosition] = useState<string>(DEFAULT_POSITION);
  const [orientation, setOrientation] = useState<Orientation>(
    Orientation.WHITE,
  );
  const [lastMove, setLastMove] = useState<LastMove | undefined>(undefined);
  const [moves, setMoves] = useState<Move[]>([]);
  const [activeMove, setActiveMove] = useState<number>(-1);
  const [isSquareLearnRun, setIsSquareLearnRun] = useState<boolean>(false);
  const [isGameLearnRun, setIsGameLearnRun] = useState<boolean>(false);

  if (speechRecognition) {
    speechRecognition.lang = "ru-RU";
    speechRecognition.continuous = false;
    speechRecognition.interimResults = false;
    speechRecognition.maxAlternatives = 1;
  }

  useEffect(() => {
    getCountData();
  }, [location.pathname]);

  const getCountData = () => {
    const countId = Number(location.pathname.split("/").pop());

    if (!countId) {
      return null;
    }

    getCount(countId).then((count) => {
      const chess = new ChessHelper();
      chess.loadPgn(count.pgn);

      setCount(count);
      setMoves(chess.history());
      setPosition(DEFAULT_POSITION);
      setLastMove(undefined);
      setActiveMove(-1);
    });
  };

  const startLearnSquares = async () => {
    const rows = ["1", "2", "3", "4", "5", "6", "7", "8"];
    const cols = ["a", "b", "c", "d", "e", "f", "g", "h"];
    const randRowId = Math.floor(Math.random() * rows.length);
    const randColId = Math.floor(Math.random() * cols.length);

    const square = `${cols[randColId]}${rows[randRowId]}`;

    setLastMove({ from: square as Square, to: square as Square });
    setIsSquareLearnRun(true);

    await playAudio(`${cols[randColId]}.mp3`);
    await playAudio(`${rows[randRowId]}.mp3`);

    squarePuzzle = blackSquares.includes(square) ? "b" : "w";

    if (speechRecognition) {
      const grammar =
        "#JSGF V1.0; grammar squares; public <square> = белое | чёрное ;";
      speechRecognitionList.addFromString(grammar, 1);
      speechRecognition.grammars = speechRecognitionList;
    }

    try {
      const result = await startRecognition();
      checkSquare(result);
    } catch {}
  };

  const stopLearnSquares = () => {
    if (speechRecognition) {
      speechRecognition.abort();
    }

    setLastMove(undefined);
    setIsSquareLearnRun(false);
  };

  const startRecognition = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      let result = "";

      if (!speechRecognition) {
        reject();
        return;
      }

      speechRecognition.onerror = () => {
        reject();
      };
      speechRecognition.onend = () => {
        resolve(result);
      };

      speechRecognition.onresult = (ev: SpeechRecognitionEvent) => {
        result = ev.results[0].item(0).transcript;
      };

      speechRecognition.start();
    });
  };

  const startLearnGame = async () => {
    if (moves.length === 0) {
      return;
    }

    setIsGameLearnRun(true);

    for (const move of moves) {
      let san = move.san;
      if (move.piece !== "p") {
        san = san.slice(1);
        await playAudio(`${move.piece.toUpperCase()}.mp3`);
      }

      for (const symbol of san) {
        if (symbol === "#") {
          await playAudio(`checkmate.mp3`);
          continue;
        }

        await playAudio(`${symbol}.mp3`);
      }

      setLastMove(move);
      setActiveMove(activeMove + 1);
      setPosition(move.after);

      await new Promise((r) => setTimeout(r, 1000));

      if (!isGameLearnRun) {
        setActiveMove(-1);
        setPosition(DEFAULT_POSITION);
        setLastMove(undefined);

        return;
      }
    }
  };

  const stopLearnGame = async () => {
    setIsGameLearnRun(false);
  };

  const checkSquare = async (value: string) => {
    if (!isSquareLearnRun) {
      return;
    }

    if (
      !whiteRecognitionResult.concat(blackRecognitionResult).includes(value)
    ) {
      try {
        const result = await startRecognition();
        checkSquare(result);
      } catch {}
      return;
    }

    (whiteRecognitionResult.includes(value) && squarePuzzle === "w") ||
    (blackRecognitionResult.includes(value) && squarePuzzle === "b")
      ? await playAudio("right.mp3")
      : await playAudio("wrong.mp3");

    startLearnSquares();
  };

  const playAudio = async (url: string) => {
    const audio = new Audio(`${process.env.PUBLIC_URL}/voices/${url}`);

    return new Promise((res) => {
      audio.play();
      audio.onended = res;
    });
  };

  return (
    <Wrapper
      isMovesOpened={true}
      isFooterOpened={false}
      movesTitle={`Позиция`}
      footerTitle={`Анализ`}
      topmenu={<Topmenu hiddenMenuButton={false}></Topmenu>}
      menu={<MenuCount></MenuCount>}
      board={
        <>
          <Board
            id="chessboard-position"
            orientation={orientation}
            position={position}
            lastMove={lastMove}
            onMove={() => undefined}
          ></Board>
        </>
      }
      buttons={
        <>
          <span
            id={styles.flip_button}
            className={`bi bi-arrow-down-up`}
            title="Развернуть доску"
            onClick={() => {
              setOrientation((state) => {
                return state === Orientation.WHITE
                  ? Orientation.BLACK
                  : Orientation.WHITE;
              });
            }}
          ></span>
          <span
            id={styles.learn_square_button}
            className={`bi bi-${isSquareLearnRun ? "eye-fill" : "eye-slash"}`}
            title="Отгадать поле"
            onClick={() => {
              isSquareLearnRun ? stopLearnSquares() : startLearnSquares();
            }}
          ></span>
          <span
            id={styles.reverse_game}
            className={`bi bi-skip-${isGameLearnRun ? "forward-fill" : "forward"}`}
            title="Надиктовать партию"
            onClick={() => {
              isGameLearnRun ? stopLearnGame() : startLearnGame();
            }}
          ></span>
        </>
      }
      movesHeader={
        count && <span className={styles.move_header}>Анализ позиции</span>
      }
      movesPgn={
        <>
          {moves.map((move: Move, moveId: number) => {
            return (
              <DummyItem
                key={`dummy-item-${moveId}`}
                move={move}
                moveNumber={
                  moves[0].color === "w"
                    ? Math.ceil(moveId / 2) + 1
                    : Math.floor((moveId + 1) / 2) + 1
                }
                isFirstLineMove={moveId === 0}
                isActive={moveId === activeMove}
                onClick={() => undefined}
              ></DummyItem>
            );
          })}
        </>
      }
      footer={null}
    ></Wrapper>
  );
};

export default CountComponent;
