import styles from "./textarea.module.scss";
import React from "react";

type TextareaProps = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
};

const Textarea = (props: TextareaProps) => {
  return (
    <textarea
      className={`form-control ${styles.textarea}`}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(event) => props.onChange(event.target.value)}
    ></textarea>
  );
};

export default Textarea;
