import { GetOpeningAnalyze } from "../../types/get-opening-analyze";
import { getAxiosInstance } from "../axios-instance";

export async function getOpeningsForAnalyze(): Promise<GetOpeningAnalyze[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ openings: GetOpeningAnalyze[] }>(`/api/v1/openings/analyze`)
      .then((data) => resolve(data.data.openings))
      .catch((error) => reject(error));
  });
}
