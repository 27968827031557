import styles from "./item.module.scss";
import React, { useState } from "react";
import ContextMenu from "../context-menu/context-menu";
import { Move } from "../../common/utils/pgn";
import { Glyphs } from "../../common/types/glyphs";

type ItemProps = {
  move: Move;
  accessKey: number[];
  isFirstLineMove: boolean;
  isAfterComment: boolean;
  isMainLine: boolean;
  onComment: (comment: string) => void;
  onUp: () => void;
  onRemove: () => void;
  onTag: () => void;
  isActive?: boolean;
  onClick?: () => void;
};

const AnalyzeItem = (props: ItemProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);

  const getMoveNumber = () => {
    if (props.move.color === "w") {
      return `${props.move.move_number}.`;
    }

    if (props.isFirstLineMove) {
      return `${props.move.move_number}. ..`;
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsOpened(true);
    setClientX(event.clientX);
    setClientY(event.clientY);
  };

  const closeMenu = () => {
    setIsOpened(false);
    setClientX(0);
    setClientY(0);
  };

  const firstNotationLetter =
    ["O-O", "O-O-O"].includes(props.move.san) || props.move.piece === "p"
      ? undefined
      : props.move.piece;
  const tileNotation = firstNotationLetter
    ? props.move.san.slice(1)
    : props.move.san;
  const comment = props.move.comment?.split(" ") || [];
  const moveNumber = getMoveNumber();

  return (
    <>
      {isOpened && (
        <ContextMenu
          accessKey={props.accessKey}
          position={props.move.after}
          clientX={clientX}
          clientY={clientY}
          onClose={() => closeMenu()}
          onComment={() => {
            props.onComment(props.move.comment);
            closeMenu();
          }}
          onTag={() => {
            props.onTag();
            closeMenu();
          }}
          onUp={() => {
            props.onUp();
            closeMenu();
          }}
          onRemove={() => {
            props.onRemove();
            closeMenu();
          }}
        ></ContextMenu>
      )}
      <div
        className={`${styles.move} ${props.isMainLine ? styles.move_main_line : ""} ${props.isActive ? styles.move_active : ""}`}
        onClick={() => props.onClick && props.onClick()}
        onContextMenu={(event) => openMenu(event)}
      >
        {moveNumber && <span className={styles.move_number}>{moveNumber}</span>}
        {firstNotationLetter && (
          <span className={styles.move_first_letter}>
            {firstNotationLetter.toLowerCase()}
          </span>
        )}
        <span className={styles.move_tile}>{tileNotation}</span>

        {props.move.glyphs?.map((glyph: string, id: number) => (
          <span className={styles.move_glyph} key={id}>
            {Glyphs[glyph as keyof typeof Glyphs]}
          </span>
        ))}
      </div>
      {props.move.comment &&
        comment.map((word: string, id: number) => (
          <span className={styles.move_comment} key={id}>
            {word}
          </span>
        ))}
    </>
  );
};

export default AnalyzeItem;
