import React from "react";
import styles from "./input.module.scss";
import { InputTypes } from "./types/input-types";

type InputProps = {
  type: InputTypes;
  value: string | number;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  onChange: (value: string | number) => void;
};

const Input = (props: InputProps) => {
  return (
    <input
      type={props.type}
      min={props.min}
      autoComplete="off"
      max={props.max}
      step={props.step}
      className={styles.input}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(event) =>
        props.onChange(
          props.type === InputTypes.NUMBER
            ? Number(event.target.value)
            : event.target.value,
        )
      }
    ></input>
  );
};

export default Input;
