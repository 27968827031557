import React, { useState } from "react";
import Modal from ".";
import { GetTechnical } from "../../common/types/get-technical";
import { TechnicalResult } from "../../common/types/technical-result";
import { editTechnical } from "../../common/api/technical/edit-technical";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import Select from "../shared/select/select";
import styles from "./modal.module.scss";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditTechnicalModalProps = {
  id: string;
  technical: GetTechnical;
  onClose: () => void;
};

const EditTechnicalModal = (props: EditTechnicalModalProps) => {
  const [technicalName, setTechnicalName] = useState(props.technical.name);
  const [technicalPgn, setTechnicalPgn] = useState(props.technical.pgn);
  const [technicalResult, setTechnicalResult] = useState(
    props.technical.result || TechnicalResult.WIN,
  );

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Изменить позицию - ${props.technical.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        editTechnical(
          props.technical.id,
          technicalName,
          technicalPgn,
          technicalResult,
        )
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Позиция успешно изменена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось обновить позицию");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название позиции</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalName}
          onChange={(value) => setTechnicalName(String(value))}
          placeholder="Введите название позиции"
        ></Input>
        <label>Pgn позиции</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalPgn}
          onChange={(value) => setTechnicalPgn(String(value))}
          placeholder="Введите pgn позиции"
        ></Input>
        <label>Цель позиции</label>
        <Select
          defaultValue={technicalResult}
          options={[
            { value: TechnicalResult.WIN, label: "На выигрыш" },
            { value: TechnicalResult.DRAW, label: "На ничью" },
          ]}
          onChange={(value) => {
            setTechnicalResult(value as TechnicalResult);
          }}
        ></Select>
      </div>
    </Modal>
  );
};

export default EditTechnicalModal;
