import { GetTechnical } from "../../types/get-technical";
import { TechnicalResult } from "../../types/technical-result";
import { getAxiosInstance } from "../axios-instance";

export async function editTechnical(
  technicalId: number,
  name: string,
  pgn: string,
  result: TechnicalResult,
): Promise<GetTechnical> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .put<{ technical: GetTechnical }>(`/api/v1/technicals/${technicalId}`, {
        name,
        pgn,
        result,
      })
      .then((data) => resolve(data.data.technical))
      .catch((error) => reject(error));
  });
}
