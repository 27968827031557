import { GetTechnical } from "../../types/get-technical";
import { TechnicalResult } from "../../types/technical-result";
import { getAxiosInstance } from "../axios-instance";

export async function createTechnical(
  group_id: number,
  name: string,
  pgn: string,
  result: TechnicalResult,
): Promise<GetTechnical> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ technical: GetTechnical }>(`/api/v1/technicals`, {
        group_id,
        name,
        pgn,
        result,
      })
      .then((data) => resolve(data.data.technical))
      .catch((error) => reject(error));
  });
}
