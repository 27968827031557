import React, { useState, useEffect } from "react";
import styles from "./menu-typical.module.scss";
import MenuOpeningTypical from "./menu-opening-typical";
import { GetOpeningTypical } from "../../../common/types/get-opening-typical";
import { getOpeningsForTypical } from "../../../common/api/openings/get-openings-typical";
import { hideMenu } from "../../../stores/menu";

const MenuTypical = () => {
  const [openings, setOpenings] = useState<GetOpeningTypical[]>([]);
  const [openedOpeningId, setOpenedOpeningId] = useState<number>(0);

  useEffect(() => {
    getOpenings();
  }, []);

  const getOpenings = () => {
    getOpeningsForTypical()
      .then((openings) => {
        setOpenings(openings);
      })
      .catch();
  };

  return (
    <>
      <div id="menu" className={`offcanvas offcanvas-end`}>
        <div id="offcanvas-header" className={styles.offcanvas_header}>
          <span>Меню</span>
          <div>
            <span
              id={styles.exit_button}
              className="bi bi-arrow-right"
              title="Выход из меню"
              onClick={() => {
                hideMenu();
              }}
            ></span>
          </div>
        </div>
        <div id="offcanvas-body" className={styles.offcanvas_body}>
          {openings.map((opening: GetOpeningTypical) => {
            return (
              <MenuOpeningTypical
                key={`opening-item-${opening.id}`}
                opening={opening}
                isOpened={opening.id === openedOpeningId}
                updateMenu={() => getOpenings()}
                toggleOpening={() =>
                  setOpenedOpeningId((state) =>
                    state === opening.id ? 0 : opening.id,
                  )
                }
              ></MenuOpeningTypical>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuTypical;
