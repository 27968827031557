import styles from "./technical.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useStoreMap } from "effector-react";
import MenuTechnical from "../menu/technical/menu-technical";
import { getTechnical } from "../../common/api/technical/get-technical";
import { GetTechnical } from "../../common/types/get-technical";
import { Orientation } from "../../common/types/orientation";
import Board from "../board/board";
import { LastMove } from "../board/types/last-move";
import { Square } from "../board/types/square";
import { ChessHelper } from "../../common/utils/chess";
import DummyItem from "../items/dummy-item";
import { Promotions } from "../board/types/promotions";
import Wrapper from "../shared/wrapper/wrapper";
import Topmenu from "../topmenu/topmenu";
import { DEFAULT_POSITION, Move } from "chess.js";
import { $userInfo } from "../../stores/user";
import { defaultSettings } from "../settings/settings";
import { $bestLines, $requestBestLines } from "../../stores/best-line";
import { Settings } from "../../common/types/settings";

const Technical = () => {
  const [technical, setTechnical] = useState<GetTechnical | null>(null);
  const [position, setPosition] = useState<string>(DEFAULT_POSITION);
  const [orientation, setOrientation] = useState<Orientation>(
    Orientation.WHITE,
  );
  const [lastMove, setLastMove] = useState<LastMove | undefined>(undefined);
  const [moves, setMoves] = useState<Move[]>([]);
  const [activeMove, setActiveMove] = useState<number>(-1);
  const settings: Record<Settings, string | number> | undefined = useStoreMap(
    $userInfo,
    (state) => state?.settings,
  );
  const bestLines = useStoreMap($bestLines, (state) => state);
  const wrapperEl = useRef(null);

  const location = useLocation();

  useEffect(() => {
    updateData();
  }, [location.pathname]);

  useEffect(() => {
    if (bestLines.length === 0) {
      return;
    }

    const chess = new ChessHelper();
    chess.init(technical?.pgn || DEFAULT_POSITION);
    for (let i = 0; i <= activeMove; i++) {
      const move = moves[i];
      chess.move(move.from, move.to, move.promotion);
    }

    const deviate = Number(
      settings
        ? settings[Settings.TECHNICAL_MAX_DEVIATE]
        : defaultSettings[Settings.TECHNICAL_MAX_DEVIATE],
    );

    try {
      const mainScore = bestLines[0].score;
      const normalMoves = bestLines.filter(
        (el) => !mainScore || Math.abs(mainScore - el.score) <= deviate,
      );
      const random = Math.floor(Math.random() * normalMoves.length);

      const newMove = normalMoves[random].moves[0];
      chess.move(newMove.from, newMove.to, newMove.promotion || "q");
      actualizeState(chess);
    } catch {}
  }, [bestLines]);

  const showNewMove = (newActiveMove: number, move?: Move) => {
    setPosition(move?.after || technical?.pgn || DEFAULT_POSITION);
    setLastMove(
      move ? { from: move.from as Square, to: move.to as Square } : undefined,
    );
    setActiveMove(newActiveMove);
  };

  const moveLeft = () => {
    const newActiveMove = Math.max(-1, activeMove - 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const moveRight = () => {
    const newActiveMove = Math.min(moves.length - 1, activeMove + 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (moves.length === 0) {
      return;
    }

    switch (event.code) {
      case "ArrowLeft":
        moveLeft();
        return;
      case "ArrowRight":
        moveRight();
        return;
    }
  };

  const updateData = () => {
    const technicalId = Number(location.pathname.split("/").pop());

    if (!technicalId) {
      return null;
    }

    getTechnical(technicalId).then((technical) => {
      setTechnical(technical);
      setPosition(technical.pgn);
    });
  };

  const onMove = (
    from: Square,
    to: Square,
    promotion: Promotions,
    finish: () => void,
  ) => {
    const chess = new ChessHelper();
    chess.init(technical?.pgn || DEFAULT_POSITION);
    for (let i = 0; i <= activeMove; i++) {
      const move = moves[i];
      chess.move(move.from, move.to, move.promotion);
    }

    const newMove = chess.move(from, to, promotion);
    if (!newMove) {
      finish();
      focus();
      return;
    }

    actualizeState(chess);
    finish();
    focus();

    const count = Number(
      settings
        ? settings[Settings.TECHNICAL_LINES_COUNT]
        : defaultSettings[Settings.TECHNICAL_LINES_COUNT],
    );
    const depth = Number(
      settings
        ? settings[Settings.TECHNICAL_DEPTH]
        : defaultSettings[Settings.TECHNICAL_DEPTH],
    );

    $requestBestLines({ fen: chess.getFen(), count, depth });
  };

  const actualizeState = (chess: ChessHelper) => {
    const moves = chess.history();
    const lastMove = moves[moves.length - 1];

    setMoves(moves);
    setActiveMove(moves.length - 1);
    setLastMove({ from: lastMove.from as Square, to: lastMove.to as Square });
    setPosition(chess.getFen());
  };

  const focus = () => {
    if (wrapperEl.current) {
      const el = wrapperEl.current as any;
      el.focus();
    }
  };

  return (
    <Wrapper
      isMovesOpened={true}
      isFooterOpened={false}
      inRef={wrapperEl}
      movesTitle={`Техническая позиция`}
      footerTitle={`Анализ`}
      onKeyDown={(event) => eventHandler(event)}
      topmenu={<Topmenu hiddenMenuButton={false}></Topmenu>}
      menu={<MenuTechnical></MenuTechnical>}
      board={
        <Board
          id="technical"
          orientation={orientation}
          position={position}
          lastMove={lastMove}
          onMove={(
            from: Square,
            to: Square,
            promotion: Promotions,
            finish: () => void,
          ) => {
            onMove(from, to, promotion, finish);
          }}
        ></Board>
      }
      buttons={
        <>
          <span
            id={styles.flip_button}
            className={`bi bi-arrow-down-up`}
            title="Развернуть доску"
            onClick={() => {
              setOrientation(
                orientation === Orientation.WHITE
                  ? Orientation.BLACK
                  : Orientation.WHITE,
              );
            }}
          ></span>
        </>
      }
      movesHeader={
        technical && (
          <span
            className={styles.move_header}
          >{`${technical.group_name} - ${technical.name}`}</span>
        )
      }
      movesPgn={
        <>
          {moves.map((move: Move, moveId: number) => {
            return (
              <DummyItem
                key={`dummy-item-${moveId}`}
                move={move}
                moveNumber={
                  moves[0].color === "w"
                    ? Math.ceil(moveId / 2) + 1
                    : Math.floor((moveId + 1) / 2) + 1
                }
                isFirstLineMove={moveId === 0}
                isActive={moveId === activeMove}
                onClick={() => {
                  const currentHistoryMove = moves[moveId];
                  setPosition(
                    currentHistoryMove?.after ||
                      technical?.pgn ||
                      DEFAULT_POSITION,
                  );
                  setLastMove(
                    currentHistoryMove
                      ? {
                          from: currentHistoryMove.from as Square,
                          to: currentHistoryMove.to as Square,
                        }
                      : undefined,
                  );
                  setActiveMove(moveId);
                }}
              ></DummyItem>
            );
          })}
        </>
      }
      footer={null}
    ></Wrapper>
  );
};

export default Technical;
