import React, { useState, useEffect } from "react";
import { GetOpeningAnalyze } from "../../../common/types/get-opening-analyze";
import { getOpeningsForAnalyze } from "../../../common/api/openings/get-openings-analyze";
import CreateOpeningModal from "../../modal/create-opening.modal";
import styles from "./menu-analyze.module.scss";
import MenuOpeningAnalyze from "./menu-opening-analyze";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

const MenuAnalyze = () => {
  const [openings, setOpenings] = useState<GetOpeningAnalyze[]>([]);
  const [openedOpeningId, setOpenedOpeningId] = useState<number>(0);

  useEffect(() => {
    getOpenings();
  }, []);

  const getOpenings = () => {
    getOpeningsForAnalyze()
      .then((openings) => {
        setOpenings(openings);
      })
      .catch(() => hideMenu());
  };

  return (
    <>
      <CreateOpeningModal
        id="create-opening-modal"
        onClose={() => getOpenings()}
      ></CreateOpeningModal>
      <div id="menu" className={`offcanvas offcanvas-end`}>
        <div id="offcanvas-header" className={styles.offcanvas_header}>
          <span>Меню</span>
          <div>
            <span
              id={styles.create_opening_button}
              className="bi bi-file-earmark-plus"
              title="Создать дебют"
              onClick={() => {
                showModal("create-opening-modal");
              }}
            ></span>
            <span
              id={styles.exit_button}
              className="bi bi-arrow-right"
              title="Выход из меню"
              onClick={() => {
                hideMenu();
              }}
            ></span>
          </div>
        </div>
        <div id="offcanvas-body" className={styles.offcanvas_body}>
          {openings.map((opening: GetOpeningAnalyze) => {
            return (
              <MenuOpeningAnalyze
                key={`opening-item-${opening.id}`}
                opening={opening}
                isOpened={opening.id === openedOpeningId}
                toggleOpening={() =>
                  setOpenedOpeningId((state) =>
                    state === opening.id ? 0 : opening.id,
                  )
                }
                updateMenu={() => getOpenings()}
              ></MenuOpeningAnalyze>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuAnalyze;
