import React, { useState } from "react";
import Modal from ".";
import { Orientation } from "../../common/types/orientation";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import { createOpening } from "../../common/api/openings/create-opening";
import Select from "../shared/select/select";
import styles from "./modal.module.scss";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateOpeningModalProps = {
  id: string;
  onClose: () => void;
};

const CreateOpeningModal = (props: CreateOpeningModalProps) => {
  const [openingName, setOpeningName] = useState("");
  const [side, setSide] = useState<Orientation>(Orientation.WHITE);

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Создание нового дебюта`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        createOpening(side, openingName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Дебют успешно создан");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать дебют");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>За какой цвет</label>
        <Select
          defaultValue={side}
          options={[
            { value: Orientation.WHITE, label: "За белых" },
            { value: Orientation.BLACK, label: "За черных" },
          ]}
          onChange={(value) => {
            setSide(value as Orientation);
          }}
        ></Select>
        <label>Название дебюта</label>
        <Input
          type={InputTypes.TEXT}
          value={openingName}
          onChange={(value) => {
            setOpeningName(String(value));
          }}
          placeholder="Введите название дебюта"
        ></Input>
      </div>
    </Modal>
  );
};

export default CreateOpeningModal;
