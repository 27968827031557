import React from "react";
import styles from "./select.module.scss";

type SelectProps = {
  defaultValue: string;
  options: Array<{ value: string; label: string }>;
  onChange: (value: string | number) => void;
};

const Select = (props: SelectProps) => {
  return (
    <select
      defaultValue={props.defaultValue}
      className={styles.select}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    >
      {props.options.map((option) => (
        <option
          className={styles.option}
          key={option.value}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
