import React from "react";
import Modal from ".";
import { GetTechnicalGroup } from "../../common/types/get-technical-group";
import { deleteTechnicalGroup } from "../../common/api/technical-group/delete-technical-group";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteTechnicalGroupModalProps = {
  id: string;
  group: GetTechnicalGroup;
  onClose: () => void;
};

const DeleteTechnicalGroupModal = (props: DeleteTechnicalGroupModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить группу - ${props.group.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        deleteTechnicalGroup(props.group.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Группа успешно удалена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить группу");
          });
      }}
    >
      <span>Вы точно хотите удалить группу?</span>
    </Modal>
  );
};

export default DeleteTechnicalGroupModal;
