import { Complexity } from "../../types/complexity";
import { GetLine } from "../../types/get-line";
import { getAxiosInstance } from "../axios-instance";

export async function editLine(
  variantId: number,
  lineId: number,
  complexity: Complexity,
): Promise<GetLine> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .put<{ line: GetLine }>(`/api/v1/lines/${variantId}`, {
        line_id: lineId,
        complexity,
      })
      .then((data) => resolve(data.data.line))
      .catch((error) => reject(error));
  });
}
