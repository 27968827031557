import React, { useState } from "react";
import Modal from ".";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import styles from "./modal.module.scss";
import { GetCount } from "../../common/types/get-count";
import { editCount } from "../../common/api/count/edit-count";
import Textarea from "../shared/textarea/textarea";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditCountModalProps = {
  id: string;
  count: GetCount;
  onClose: () => void;
};

const EditCountModal = (props: EditCountModalProps) => {
  const [countName, setCountName] = useState(props.count.name);
  const [countPgn, setCountPgn] = useState(props.count.pgn || "");

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Изменить партию - ${props.count.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        editCount(props.count.id, countName, countPgn)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Партия успешно изменена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось изменить партию");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название партии</label>
        <Input
          type={InputTypes.TEXT}
          value={countName}
          onChange={(value) => setCountName(String(value))}
          placeholder="Введите название партии"
        ></Input>
        <label>Pgn партии</label>
        <Textarea
          value={countPgn}
          onChange={(value) => setCountPgn(String(value))}
          placeholder="Введите pgn позиции"
        ></Textarea>
      </div>
    </Modal>
  );
};

export default EditCountModal;
