import { GetCount } from "../../types/get-count";
import { getAxiosInstance } from "../axios-instance";

export async function getCount(countId: number): Promise<GetCount> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ count: GetCount }>(`/api/v1/counts/${countId}`)
      .then((data) => resolve(data.data.count))
      .catch((error) => reject(error));
  });
}
