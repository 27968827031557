import React, { useState, useEffect } from "react";
import { getCounts } from "../../../common/api/count/get-count-list";
import { GetCount } from "../../../common/types/get-count";
import CreateCountModal from "../../modal/create-count.modal";
import MenuCountItem from "./menu-count-item";
import styles from "./menu-count.module.scss";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

const MenuCount = () => {
  const [counts, setCounts] = useState<GetCount[]>([]);

  useEffect(() => {
    getCountList();
  }, []);

  const getCountList = () => {
    getCounts()
      .then((counts) => {
        setCounts(counts);
      })
      .catch(() => undefined);
  };

  return (
    <>
      <CreateCountModal
        id="create-count-modal"
        onClose={() => getCountList()}
      ></CreateCountModal>
      <div id="menu" className={`offcanvas offcanvas-end`}>
        <div id="offcanvas-header" className={styles.offcanvas_header}>
          <span id="menu-title">Меню</span>
          <div>
            <span
              id={styles.create_group_button}
              className="bi bi-file-earmark-plus"
              title="Создать партию"
              onClick={() => {
                showModal("create-count-modal");
              }}
            ></span>
            <span
              id={styles.exit_button}
              className="bi bi-arrow-right"
              title="Выход из меню"
              onClick={() => {
                hideMenu();
              }}
            ></span>
          </div>
        </div>
        <div id="offcanvas-body" className={styles.offcanvas_body}>
          {counts.map((count: GetCount) => {
            return (
              <MenuCountItem
                key={`count-item-${count.id}`}
                count={count}
                updateMenu={() => getCountList()}
              ></MenuCountItem>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuCount;
