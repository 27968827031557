import { GetTypical } from "../../types/get-typical";
import { getAxiosInstance } from "../axios-instance";

export async function getTypicals(variantId: number): Promise<GetTypical[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ typicals: GetTypical[] }>(`/api/v1/typicals/variants/${variantId}`)
      .then((data) => resolve(data.data.typicals))
      .catch((error) => reject(error));
  });
}
