import React from "react";
import { useStoreMap } from "effector-react";
import styles from "./alert.module.scss";
import { $alert } from "../../stores/alert";

const CustomAlert = () => {
  const alert = useStoreMap($alert, (state) => state);

  if (!alert.has) {
    return null;
  }

  return (
    <div id={styles.alert} className={`alert alert-${alert.type}`} role="alert">
      {alert.message}
    </div>
  );
};

export default CustomAlert;
