import { GetOpeningTypical } from "../../types/get-opening-typical";
import { getAxiosInstance } from "../axios-instance";

export async function getOpeningsForTypical(): Promise<GetOpeningTypical[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ openings: GetOpeningTypical[] }>(`/api/v1/openings/typical`)
      .then((data) => resolve(data.data.openings))
      .catch((error) => reject(error));
  });
}
