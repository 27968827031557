import { createEvent, createStore, createEffect, sample } from "effector";
import { AlertTypes } from "../components/alerts/types/alert-types";

const ALERT_TIMEOUT = 3000;

type Alert = {
  message: string;
  type: AlertTypes;
  has: boolean;
};

const initialState: Alert = {
  message: "Hello",
  type: AlertTypes.SUCCESS,
  has: false,
};

export const showSuccessAlert = createEvent<string>();
export const showDangerAlert = createEvent<string>();
export const $alert = createStore<Alert>(initialState);

const showAlert = createEvent<Alert>();
const hideAlert = createEvent();

const alertAutoHide = createEffect(`alert_autohide`).use(() => wait());

$alert.on(showAlert, (_, value) => value);
$alert.on(hideAlert, () => initialState);

sample({
  clock: showSuccessAlert,
  fn: (value: string) => {
    return {
      message: value,
      type: AlertTypes.SUCCESS,
      has: true,
    };
  },
  target: showAlert,
});

sample({
  clock: showDangerAlert,
  fn: (value: string) => {
    return {
      message: value,
      type: AlertTypes.DANGER,
      has: true,
    };
  },
  target: showAlert,
});

sample({
  clock: showAlert,
  target: alertAutoHide,
});

const wait = () => {
  return new Promise(() => {
    setTimeout(hideAlert, ALERT_TIMEOUT);
  });
};
