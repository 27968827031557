import React, { useState } from "react";
import Modal from ".";
import { GetTechnicalGroup } from "../../common/types/get-technical-group";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import styles from "./modal.module.scss";
import { editTechnicalGroup } from "../../common/api/technical-group/edit-technical-group";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type EditTechnicalGroupModalProps = {
  id: string;
  onClose: () => void;
  group: GetTechnicalGroup;
};

const EditTechnicalGroupModal = (props: EditTechnicalGroupModalProps) => {
  const [technicalGroupName, setTechnicalGroupName] = useState(
    props.group.name,
  );

  return (
    <Modal
      id={props.id}
      cancelName="Не менять"
      okName="Изменить"
      title={`Изменить группу - ${props.group.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        if (technicalGroupName.length < 3) {
          showDangerAlert("Название группы должно содержать минимум 3 символа");
          return;
        }

        editTechnicalGroup(props.group.id, technicalGroupName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Группа успешно обновлена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось обновить группу");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название группы</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalGroupName}
          onChange={(value) => {
            setTechnicalGroupName(String(value));
          }}
          placeholder="Введите название группы"
        ></Input>
      </div>
    </Modal>
  );
};

export default EditTechnicalGroupModal;
