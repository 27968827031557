export function getAuthToken() {
  return window.localStorage.getItem("auth_token");
}

export function getRefreshToken() {
  return window.localStorage.getItem("refresh_token");
}

export function getRefreshProcessFlag() {
  return window.localStorage.getItem("refresh_process_flag");
}

export function saveAuthToken(authToken: string) {
  return window.localStorage.setItem("auth_token", authToken);
}

export function saveRefreshToken(refreshToken: string) {
  return window.localStorage.setItem("refresh_token", refreshToken);
}

export function setRefreshProcessFlag() {
  return window.localStorage.setItem("refresh_process_flag", "1");
}

export function removeAuthToken() {
  return window.localStorage.removeItem("auth_token");
}

export function removeRefreshToken() {
  return window.localStorage.removeItem("refresh_token");
}

export function removeRefreshProcessFlag() {
  return window.localStorage.removeItem("refresh_process_flag");
}
