import styles from "./analyze.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useStoreMap } from "effector-react";
import MenuAnalyze from "../menu/analyze/menu-analyze";
import { Orientation } from "../../common/types/orientation";
import Wrapper from "../shared/wrapper/wrapper";
import Topmenu from "../topmenu/topmenu";
import Board from "../board/board";
import { DEFAULT_POSITION, Move, Square } from "chess.js";
import { Promotions } from "../board/types/promotions";
import { getVariant } from "../../common/api/variants/get-variant";
import { GetVariantAnalyze } from "../../common/types/get-variant-analyze";
import { LastMove } from "../board/types/last-move";
import { ChessHelper } from "../../common/utils/chess";
import { cloneDeep } from "lodash";
import AnalyzeMoves from "../analyze-moves/analyze-moves";
import DummyItem from "../items/dummy-item";
import { debounced } from "../../common/utils/debounce";
import CommentModal from "../modal/comment-modal";
import DeleteMovesModal from "../modal/delete-moves.modal";
import { editVariant } from "../../common/api/variants/edit-variant";
import { MoveTree, toPgn, toTree } from "../../common/utils/pgn";
import { showModal } from "../../stores/modal";
import {
  $analyzeData,
  $startAnalyze,
  $stopAnalyze,
  $updateAnalyze,
} from "../../stores/analyze";
import { AnalyzeLine } from "../../common/types/analyze-line";
import AddTagsModal from "../modal/add-tags.modal";
import { showSuccessAlert } from "../../stores/alert";

const Analyze = () => {
  const [variant, setVariant] = useState<GetVariantAnalyze | undefined>(
    undefined,
  );
  const [movesTree, setMovesTree] = useState<MoveTree[]>([]);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [orientation, setOrientation] = useState(Orientation.WHITE);
  const [lastMove, setLastMove] = useState<LastMove | undefined>(undefined);
  const [activeMoveId, setActiveMoveId] = useState<number[]>([]);
  const [contextMenuActiveMoveId, setContextMenuActiveMoveId] = useState<
    number[]
  >([]);
  const [comment, setComment] = useState("");
  const [glyphs, setGlyphs] = useState<string[]>([]);
  const [isEngineRun, setIsEngineRun] = useState(false);
  const analyzeLines = useStoreMap($analyzeData, (analyzeData) => analyzeData);

  const location = useLocation();
  const wrapperEl = useRef(null);

  useEffect(() => {
    updateData();
    focus();

    return () => {
      if (isEngineRun) {
        stopEngine();
      }
    };
  }, [location.pathname]);

  const moveLeft = () => {
    const [lastActiveMoveId] = activeMoveId.slice(-1);

    if (activeMoveId.length === 1 && lastActiveMoveId === 0) {
      setActiveMoveId([]);
      setLastMove(undefined);
      setPosition(DEFAULT_POSITION);

      if (isEngineRun) {
        debounced(() => {
          updateEngine(DEFAULT_POSITION);
        });
      }

      return;
    }

    const newActiveMoveId = cloneDeep(activeMoveId);

    if (lastActiveMoveId > 0) {
      newActiveMoveId.pop();
      newActiveMoveId.push(lastActiveMoveId - 1);
      setActiveMoveId(newActiveMoveId);
      const newActiveMove = getMoveByAccessKey(movesTree, newActiveMoveId);
      if (newActiveMove?.move) {
        setPosition(newActiveMove.move.after);
        setLastMove(newActiveMove.move);

        if (isEngineRun) {
          debounced(() => {
            updateEngine(newActiveMove.move.after);
          });
        }
      }
    }
  };

  const moveRight = () => {
    const newActiveMoveId = cloneDeep(activeMoveId);
    const lastActiveMoveId = newActiveMoveId.pop();
    newActiveMoveId.push(
      lastActiveMoveId === undefined ? 0 : lastActiveMoveId + 1,
    );
    const newActiveMove = getMoveByAccessKey(movesTree, newActiveMoveId);

    if (!newActiveMove) {
      return;
    }

    setActiveMoveId(newActiveMoveId);
    setPosition(newActiveMove.move.after);
    setLastMove(newActiveMove.move);

    if (isEngineRun) {
      debounced(() => {
        updateEngine(newActiveMove.move.after);
      });
    }
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (movesTree.length === 0) {
      return;
    }

    switch (event.code) {
      case "ArrowLeft":
        moveLeft();
        return;
      case "ArrowRight":
        moveRight();
        return;
    }
  };

  const updateData = () => {
    const variantId = Number(location.pathname.split("/").pop());

    if (!variantId) {
      return;
    }

    getVariant(variantId)
      .then((variant: GetVariantAnalyze) => {
        const tree = toTree(variant.pgn || "");
        setVariant(variant);
        setMovesTree(tree);
        setPosition(DEFAULT_POSITION);
        setOrientation(variant.side || Orientation.WHITE);
        setLastMove(undefined);
        setActiveMoveId([]);
      })
      .catch(() => undefined);
  };

  const startEngine = () => {
    $startAnalyze(position);
    setIsEngineRun(true);
  };

  const updateEngine = (position: string) => {
    $updateAnalyze(position);
  };

  const stopEngine = () => {
    $stopAnalyze();
    setIsEngineRun(false);
  };

  const getMoveByAccessKey = (
    tree: MoveTree[],
    activeMove0: number[],
  ): MoveTree | undefined => {
    const activeMove = cloneDeep(activeMove0);

    const firstElement = activeMove.shift();

    if (firstElement === undefined || firstElement === -1) {
      return undefined;
    }

    const secondElement = activeMove.shift();

    if (secondElement === undefined) {
      return tree[firstElement];
    }

    return getMoveByAccessKey(
      tree[firstElement].variants[secondElement],
      activeMove,
    );
  };

  const onMove = (
    from: Square,
    to: Square,
    promotion: Promotions,
    finish: () => void,
  ) => {
    const chess = new ChessHelper();
    chess.init(position);

    const move = chess.move(from, to, promotion);
    if (!move) {
      focus();
      finish();
      return;
    }

    const newActiveMoveId = cloneDeep(activeMoveId);
    const lastActiveMoveId = newActiveMoveId.pop();
    newActiveMoveId.push(
      lastActiveMoveId === undefined ? 0 : lastActiveMoveId + 1,
    );
    const newActiveMove = getMoveByAccessKey(movesTree, newActiveMoveId);
    const moveNumber = chess.getMoveNumber();

    //нет следующего хода, добавляем ход
    if (!newActiveMove) {
      if (move) {
        addAfter(move, moveNumber);
      }

      if (isEngineRun) {
        debounced(() => {
          updateEngine(chess.getFen());
        });
      }

      focus();
      finish();
      return;
    }

    // следующий ход есть и он равен введенному
    if (move.after === newActiveMove.move.after) {
      setActiveMoveId(newActiveMoveId);
      setLastMove(newActiveMove.move);
      setPosition(newActiveMove.move.after);

      if (isEngineRun) {
        debounced(() => {
          updateEngine(newActiveMove.move.after);
        });
      }

      focus();
      finish();
      return;
    }

    // следующий ход есть в вариантах
    for (const variant of newActiveMove.variants) {
      if (move.after !== variant[0].move.after) {
        continue;
      }

      newActiveMoveId.push(newActiveMove.variants.indexOf(variant));
      newActiveMoveId.push(0);
      setActiveMoveId(newActiveMoveId);
      setLastMove(variant[0].move);
      setPosition(variant[0].move.after);

      if (isEngineRun) {
        debounced(() => {
          updateEngine(variant[0].move.after);
        });
      }

      focus();
      finish();
      return;
    }

    // следующего хода нет, добавляем вариант
    addVariant(move, moveNumber);

    if (isEngineRun) {
      debounced(() => {
        updateEngine(chess.getFen());
      });
    }

    focus();
    finish();
  };

  const addAfter = (move: Move, moveNumber: number) => {
    const newActiveMoveId = cloneDeep(activeMoveId);
    const lastNewActiveMoveId = newActiveMoveId.pop() || 0;
    newActiveMoveId.push(lastNewActiveMoveId + 1);
    const newMovesTree = cloneDeep(movesTree);

    const preparedMove = {
      color: move.color,
      from: move.from,
      to: move.to,
      piece: move.piece,
      promotion: move.promotion || null,
      san: move.san,
      before: move.before,
      after: move.after,
      comment: "",
      move_number: moveNumber,
    };

    if (activeMoveId.length <= 1) {
      newMovesTree.push({
        move: preparedMove,
        variants: [],
      });
    } else {
      const firstElement = newActiveMoveId.pop() || 0;
      const secondElement = newActiveMoveId.pop() || 0;
      const prevMoveLine = getMoveByAccessKey(newMovesTree, newActiveMoveId);

      if (prevMoveLine) {
        prevMoveLine.variants[secondElement].push({
          move: preparedMove,
          variants: [],
        });
        newActiveMoveId.push(secondElement);
        newActiveMoveId.push(firstElement);
      }
    }

    if (isEngineRun) {
      debounced(() => {
        updateEngine(move.after);
      });
    }

    setMovesTree(newMovesTree);
    setPosition(move.after);
    setLastMove(move);
    setActiveMoveId(newActiveMoveId);
  };

  const addVariant = (move: Move, moveNumber: number) => {
    const newActiveMoveId = cloneDeep(activeMoveId);
    const lastNewActiveMoveId = newActiveMoveId.pop() || 0;
    newActiveMoveId.push(lastNewActiveMoveId + 1);
    const newMovesTree = cloneDeep(movesTree);

    const nextMove = getMoveByAccessKey(newMovesTree, newActiveMoveId);

    if (!nextMove) {
      return;
    }

    nextMove.variants.push([
      {
        move: {
          color: move.color,
          from: move.from,
          to: move.to,
          piece: move.piece,
          promotion: move.promotion || null,
          san: move.san,
          before: move.before,
          after: move.after,
          comment: "",
          move_number: moveNumber,
        },
        variants: [],
      },
    ]);

    setMovesTree(newMovesTree);
    setPosition(move.after);
    setLastMove(move);
    setActiveMoveId([...newActiveMoveId, nextMove.variants.length - 1, 0]);
  };

  const addComment = (comment: string) => {
    const newMovesTree = cloneDeep(movesTree);
    const newContextMenuActiveMoveId = cloneDeep(contextMenuActiveMoveId);

    const currentMove = getMoveByAccessKey(
      newMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!currentMove) {
      return;
    }

    currentMove.move.comment = comment;

    setMovesTree(newMovesTree);
  };

  const changeGlyphs = (glyphs: string[]) => {
    const newMovesTree = cloneDeep(movesTree);
    const newContextMenuActiveMoveId = cloneDeep(contextMenuActiveMoveId);

    const currentMove = getMoveByAccessKey(
      newMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!currentMove) {
      return;
    }

    currentMove.move.glyphs = glyphs;

    setMovesTree(newMovesTree);
  };

  const deleteMoves = () => {
    if (
      contextMenuActiveMoveId.length === 1 &&
      contextMenuActiveMoveId[0] === 0
    ) {
      setMovesTree([]);
      setPosition(DEFAULT_POSITION);
      setLastMove(undefined);
      setActiveMoveId([]);
      setContextMenuActiveMoveId([]);

      if (isEngineRun) {
        debounced(() => {
          updateEngine(DEFAULT_POSITION);
        });
      }

      return;
    }

    const newContextMenuActiveMoveId = cloneDeep(contextMenuActiveMoveId);
    const newMovesTree = cloneDeep(movesTree);
    const firstElement = newContextMenuActiveMoveId.pop() || 0;
    const secondElement = newContextMenuActiveMoveId.pop() || 0;
    const currentMove = getMoveByAccessKey(
      newMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!currentMove) {
      return;
    }

    // удаляем вариант сразу
    if (firstElement === 0) {
      currentMove.variants.splice(secondElement, 1);
    } else {
      // удаляем вариант частично
      currentMove.variants[secondElement] = currentMove.variants[
        secondElement
      ].slice(0, firstElement);
      newContextMenuActiveMoveId.push(secondElement);
      newContextMenuActiveMoveId.push(firstElement - 1);
    }

    const newCurrentMove = getMoveByAccessKey(
      newMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!newCurrentMove) {
      return;
    }

    setMovesTree(newMovesTree);
    setPosition(newCurrentMove.move.after);
    setLastMove(newCurrentMove.move);
    setActiveMoveId(newContextMenuActiveMoveId);

    if (isEngineRun) {
      debounced(() => {
        updateEngine(DEFAULT_POSITION);
      });
    }
  };

  const onUp = (activeMove: number[]) => {
    const newContextMenuActiveMoveId = cloneDeep(activeMove);
    const localMovesTree = cloneDeep(movesTree);
    const firstElement = newContextMenuActiveMoveId.pop() || 0;
    const secondElement = newContextMenuActiveMoveId.pop() || 0;
    const currentMove = getMoveByAccessKey(
      localMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!currentMove) {
      return;
    }

    if (secondElement > 0) {
      const beforeElement = cloneDeep(currentMove.variants[secondElement - 1]);
      currentMove.variants[secondElement - 1] = cloneDeep(
        currentMove.variants[secondElement],
      );
      currentMove.variants[secondElement] = beforeElement;
      newContextMenuActiveMoveId.push(secondElement - 1);
      newContextMenuActiveMoveId.push(firstElement);

      setMovesTree(localMovesTree);
      setPosition(currentMove.move.after);
      setLastMove(currentMove.move);
      setActiveMoveId(newContextMenuActiveMoveId);

      return;
    }

    let newMovesTree = cloneDeep(movesTree);

    // поднимаем до главной линии
    if (newContextMenuActiveMoveId.length === 1) {
      const mainFirstPart = cloneDeep(movesTree).slice(
        0,
        newContextMenuActiveMoveId[0],
      );
      const oldMainSecondPart = cloneDeep(movesTree).slice(
        newContextMenuActiveMoveId[0],
      );
      oldMainSecondPart[0].variants = [];
      const mainSecondPart = cloneDeep(currentMove.variants.splice(0, 1)[0]);
      mainSecondPart[0].variants = cloneDeep(currentMove.variants);
      mainSecondPart[0].variants.unshift(oldMainSecondPart);
      newMovesTree = mainFirstPart.concat(mainSecondPart);
    } else {
      const thirdElement = newContextMenuActiveMoveId.pop() || 0;
      const fourthElement = newContextMenuActiveMoveId.pop() || 0;
      const currentMove1 = getMoveByAccessKey(
        newMovesTree,
        newContextMenuActiveMoveId,
      );
      if (!currentMove1) {
        return;
      }

      const innerTree = cloneDeep(currentMove1.variants[fourthElement]);
      const mainFirstPart1 = cloneDeep(innerTree).slice(0, thirdElement);
      const oldMainSecondPart1 = cloneDeep(innerTree).slice(thirdElement);
      oldMainSecondPart1[0].variants = [];
      const mainSecondPart1 = cloneDeep(currentMove.variants.splice(0, 1)[0]);
      mainSecondPart1[0].variants = cloneDeep(currentMove.variants);
      mainSecondPart1[0].variants.unshift(oldMainSecondPart1);

      currentMove1.variants[fourthElement] =
        mainFirstPart1.concat(mainSecondPart1);

      newContextMenuActiveMoveId.push(fourthElement);
      newContextMenuActiveMoveId.push(thirdElement);
    }

    const newCurrentMove = getMoveByAccessKey(
      newMovesTree,
      newContextMenuActiveMoveId,
    );

    if (!newCurrentMove) {
      return;
    }

    setMovesTree(newMovesTree);
    setPosition(newCurrentMove.move.after);
    setLastMove(newCurrentMove.move);
    setActiveMoveId(newContextMenuActiveMoveId);
  };

  const saveVariant = () => {
    if (!variant) {
      return;
    }

    const pgn = toPgn(movesTree);

    editVariant(variant.id, variant.name, pgn).then(() => {
      updateData();
      showSuccessAlert("Вариант успешно обновлен");
    });
  };

  const focus = () => {
    if (wrapperEl.current) {
      const el = wrapperEl.current as any;
      el.focus();
    }
  };

  return (
    <>
      <CommentModal
        id="comment"
        comment={comment}
        onComment={(comment: string) => addComment(comment)}
      ></CommentModal>
      <AddTagsModal
        id="tag"
        glyphs={glyphs}
        onChange={(glyphs: string[]) => changeGlyphs(glyphs)}
      ></AddTagsModal>
      <DeleteMovesModal
        id="delete-moves"
        onDelete={() => deleteMoves()}
      ></DeleteMovesModal>
      <Wrapper
        isMovesOpened={true}
        isFooterOpened={false}
        inRef={wrapperEl}
        onKeyDown={(event) => {
          eventHandler(event);
        }}
        movesTitle={`Вариант`}
        footerTitle={`Анализ`}
        topmenu={<Topmenu hiddenMenuButton={false}></Topmenu>}
        menu={<MenuAnalyze></MenuAnalyze>}
        board={
          <Board
            id="analyze"
            orientation={orientation}
            position={position}
            lastMove={lastMove}
            onMove={(
              from: Square,
              to: Square,
              promotion: Promotions,
              finish: () => void,
            ) => {
              onMove(from, to, promotion, finish);
            }}
          ></Board>
        }
        buttons={
          <>
            <span
              id={styles.analyze_button}
              className={`bi bi-${isEngineRun ? "gear-fill" : "gear"}`}
              title={isEngineRun ? "Остановить анализ" : "Анализировать"}
              style={variant ? {} : { pointerEvents: "none" }}
              onClick={() => (isEngineRun ? stopEngine() : startEngine())}
            ></span>
            <span
              id={styles.flip_button}
              className={`bi bi-arrow-down-up`}
              title="Развернуть доску"
              style={variant ? {} : { pointerEvents: "none" }}
              onClick={() => {
                setOrientation((state) => {
                  return state === Orientation.WHITE
                    ? Orientation.BLACK
                    : Orientation.WHITE;
                });
              }}
            ></span>
            <span
              id={styles.save_button}
              className={`bi bi-floppy`}
              title="Сохранить вариант"
              style={variant ? {} : { pointerEvents: "none" }}
              onClick={() => saveVariant()}
            ></span>
          </>
        }
        movesHeader={
          variant && (
            <span
              className={styles.move_header}
            >{`${variant.opening_name} - ${variant.name}`}</span>
          )
        }
        movesPgn={
          <AnalyzeMoves
            moves={[movesTree]}
            activeKey={activeMoveId}
            accessKey={[]}
            onClick={(activeMoveId: number[]) => {
              const newActiveMove = getMoveByAccessKey(movesTree, activeMoveId);
              if (!newActiveMove) {
                return;
              }
              setActiveMoveId(activeMoveId);
              setLastMove(newActiveMove.move);
              setPosition(newActiveMove.move.after);

              if (isEngineRun) {
                debounced(() => {
                  updateEngine(newActiveMove.move.after);
                });
              }
            }}
            onTag={(activeMoveId: number[]) => {
              setContextMenuActiveMoveId(activeMoveId);

              const activeMove = getMoveByAccessKey(movesTree, activeMoveId);
              setGlyphs(activeMove?.move.glyphs || []);
              showModal("tag");
            }}
            onComment={(activeMoveId: number[], comment: string) => {
              setComment(comment);
              setContextMenuActiveMoveId(activeMoveId);

              showModal("comment");
            }}
            onUp={(activeMoveId: number[]) => {
              onUp(activeMoveId);
            }}
            onRemove={(activeMoveId: number[]) => {
              setContextMenuActiveMoveId(activeMoveId);
              showModal("delete-moves");
            }}
          ></AnalyzeMoves>
        }
        footer={
          <div id={styles.engine_output}>
            {analyzeLines.map(
              (analyzeLine: AnalyzeLine, analyzeLineId: number) => (
                <div className={styles.analyze_line} key={analyzeLineId}>
                  <div className={styles.analyze_depth}>
                    {`${analyzeLine.depth}/${analyzeLine.seldepth}`}
                  </div>
                  <div className={styles.analyze_score}>
                    {analyzeLine.score?.toFixed(2)}
                  </div>
                  <div className={styles.analyze_moves}>
                    {analyzeLine.moves.map((move: Move, moveId: number) => {
                      const moveNumber =
                        analyzeLine.moveNumber +
                        (move.color === "w"
                          ? Math.ceil(moveId / 2)
                          : Math.floor((moveId + 1) / 2));

                      return (
                        <DummyItem
                          move={move}
                          moveNumber={moveNumber}
                          isFirstLineMove={moveId === 0}
                          isActive={false}
                          key={moveId}
                        ></DummyItem>
                      );
                    })}
                  </div>
                </div>
              ),
            )}
          </div>
        }
      ></Wrapper>
    </>
  );
};

export default Analyze;
