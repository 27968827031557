import { Square } from "chess.js";
import React, { SyntheticEvent } from "react";
import { Orientation } from "../../../common/types/orientation";
import { Promotions } from "../types/promotions";
import styles from "./promotion.module.scss";

type PromotionProps = {
  square: Square;
  orientation: Orientation;
  onClick?: (promotion: Promotions) => void;
};
const Promotion = (props: PromotionProps) => {
  const onClick = (event: SyntheticEvent, promotion: Promotions) => {
    event.preventDefault();

    if (props.onClick) {
      props.onClick(promotion);
    }
  };

  const getColor = (): string => {
    const row = Number(props.square[1]);
    return row === 8 ? "w" : "b";
  };

  return (
    <div
      id={styles.promotion}
      className={
        styles[
          `${props.orientation === Orientation.WHITE ? "w" : "b"}${props.square}`
        ]
      }
    >
      <img
        id={`figure_q`}
        draggable={false}
        className={styles.img}
        onClick={(event) => onClick(event, "q")}
        src={`${process.env.PUBLIC_URL}/chesspieces/${getColor()}Q.svg`}
      />
      <img
        id={`figure_r`}
        draggable={false}
        className={styles.img}
        onClick={(event) => onClick(event, "r")}
        src={`${process.env.PUBLIC_URL}/chesspieces/${getColor()}R.svg`}
      />
      <img
        id={`figure_b`}
        draggable={false}
        className={styles.img}
        onClick={(event) => onClick(event, "b")}
        src={`${process.env.PUBLIC_URL}/chesspieces/${getColor()}B.svg`}
      />
      <img
        id={`figure_n`}
        draggable={false}
        className={styles.img}
        onClick={(event) => onClick(event, "n")}
        src={`${process.env.PUBLIC_URL}/chesspieces/${getColor()}N.svg`}
      />
    </div>
  );
};

export default Promotion;
