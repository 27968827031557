import { GetTechnicalGroup } from "../../types/get-technical-group";
import { getAxiosInstance } from "../axios-instance";

export async function createTechncialGroup(
  name: string,
): Promise<GetTechnicalGroup> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ technical_group: GetTechnicalGroup }>(`/api/v1/technical-group`, {
        name,
      })
      .then((data) => resolve(data.data.technical_group))
      .catch((error) => reject(error));
  });
}
