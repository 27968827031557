import React, { useState } from "react";
import Modal from ".";
import { GetOpeningAnalyze } from "../../common/types/get-opening-analyze";
import styles from "./modal.module.scss";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import { createVariant } from "../../common/api/variants/create-variant";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateVariantModalProps = {
  id: string;
  opening: GetOpeningAnalyze;
  onClose: () => void;
};

const CreateVariantModal = (props: CreateVariantModalProps) => {
  const [variantName, setVariantName] = useState("");

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Вариант для дебюта - ${props.opening.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        createVariant(props.opening.id, variantName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Вариант успешно создан");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать вариант");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={variantName}
          onChange={(value) => {
            setVariantName(String(value));
          }}
          placeholder="Введите название варианта"
        ></Input>
      </div>
    </Modal>
  );
};

export default CreateVariantModal;
