import React, { useState, useEffect } from "react";
import { Orientation } from "../../common/types/orientation";
import styles from "./board.module.scss";
import SquareComponent from "./square/square";
import { ChessHelper } from "../../common/utils/chess";
import { Square, SQUARES } from "chess.js";
import { Board } from "./types/board";

type DummyBoardProps = {
  id: string;
  orientation: Orientation;
  position: string;
};

const DummyBoard = (props: DummyBoardProps) => {
  const [squares, setSquares] = useState<Square[]>([]);
  const [board, setBoard] = useState<Board>([]);

  useEffect(() => {
    setPosition();
  }, []);

  const setPosition = () => {
    const chess = new ChessHelper();
    chess.init(props.position);

    const squares = SQUARES;
    const board = chess.board();
    if (props.orientation === Orientation.BLACK) {
      squares.reverse();
      board.forEach((row) => row.reverse());
      board.reverse();
    }

    setSquares(squares);
    setBoard(board);
  };

  return (
    <div id={props.id} className={styles.board}>
      {board.map((row, indexRow) => {
        return (
          <div key={indexRow} className={styles.row}>
            {row.map((_, indexSquare) => {
              return (
                <div key={indexSquare} className={styles.square}>
                  <SquareComponent
                    square={squares[indexRow * 8 + indexSquare]}
                    figure={board[indexRow][indexSquare]}
                    dummy={true}
                    editable={false}
                    active={false}
                    movable={false}
                    availableForMove={false}
                  ></SquareComponent>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DummyBoard;
