import axios, { HttpStatusCode } from "axios";
import {
  removeRefreshToken,
  getAuthToken,
  getRefreshToken,
  removeAuthToken,
  saveAuthToken,
  saveRefreshToken,
  setRefreshProcessFlag,
  getRefreshProcessFlag,
  removeRefreshProcessFlag,
} from "../utils/local-storage";
import { AxiosException } from "../exceptions/axios.exception";
import { GetTokens } from "../types/get-tokens";

const UNAUTHORIZED_URLS = [
  "/api/v1/auth/login",
  "/api/v1/auth/refresh",
  "/api/v1/auth/logout",
];
const REQUEST_REPEAT_INTERVAL = 1000;

export function getAxiosInstance() {
  const axiosInstance = getBaseAxiosInstance();

  axiosInstance.interceptors.request.use(
    function (config) {
      if (config.url && UNAUTHORIZED_URLS.includes(config.url)) {
        return config;
      }

      const authToken = getAuthToken();

      if (!authToken) {
        removeRefreshToken();
        throw new AxiosException();
      }

      config.headers.Authorization = `Bearer ${authToken}`;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosException) {
      const code = error.response?.status || Number(error.code);

      if (code === HttpStatusCode.Forbidden) {
        return Promise.reject(error);
      }

      if (
        (error.config?.url && UNAUTHORIZED_URLS.includes(error.config.url)) ||
        code !== HttpStatusCode.Unauthorized
      ) {
        return Promise.reject(error);
      }

      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        removeAuthToken();
        Promise.reject(new AxiosException());
      }

      return new Promise((resolve, reject) => {
        if (refreshToken && !getRefreshProcessFlag()) {
          refreshProcess(refreshToken, () => {
            return reject(error);
          });
        }

        const interval = setInterval(() => {
          if (error.config && !getRefreshProcessFlag()) {
            error.config.headers.Authorization = `Bearer ${getAuthToken()}`;
            getBaseAxiosInstance()(error.config)
              .then((value) => {
                clearInterval(interval);

                resolve(value);
              })
              .catch((newError) => {
                removeAuthToken();
                removeRefreshToken();
                clearInterval(interval);

                reject(newError);
              });
          }
        }, REQUEST_REPEAT_INTERVAL);
      });
    },
  );

  return axiosInstance;
}

function getBaseAxiosInstance() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function refreshProcess(refreshToken: string, cb: () => void) {
  setRefreshProcessFlag();
  getBaseAxiosInstance()
    .post<{ auth: GetTokens }>(`/api/v1/auth/refresh`, {
      refresh_token: refreshToken,
    })
    .then((value) => {
      saveAuthToken(value.data.auth.auth_token);
      saveRefreshToken(value.data.auth.refresh_token);
      removeRefreshProcessFlag();
    })
    .catch(() => {
      removeAuthToken();
      removeRefreshToken();
      removeRefreshProcessFlag();

      return cb();
    });
}
