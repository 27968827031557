import { createEffect } from "effector";
import { getAxiosInstance } from "../axios-instance";
import { showDangerAlert } from "../../../stores/alert";
import {
  getRefreshToken,
  removeAuthToken,
  removeRefreshToken,
} from "../../utils/local-storage";

export const $logoutFx = createEffect<void, void>();

$logoutFx.use(async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    removeAuthToken();
    showDangerAlert("Не удалось выйти из системы");
    return;
  }
  await logout();
});

async function logout(): Promise<void> {
  try {
    await getAxiosInstance().post(`/api/v1/auth/logout`, {
      refresh_token: getRefreshToken(),
    });

    removeAuthToken();
    removeRefreshToken();
    window.location.reload();
  } catch (error) {
    showDangerAlert("Не удалось выйти из системы");
  }
}
