import React, { useState, useEffect } from "react";
import Modal from ".";
import styles from "./modal.module.scss";
import { hideModal } from "../../stores/modal";
import { GLYPHS_LIST, Glyphs } from "../../common/types/glyphs";

type AddTagsModalProps = {
  id: string;
  glyphs: string[];
  onChange: (glyphs: string[]) => void;
};

const AddTagsModal = (props: AddTagsModalProps) => {
  const [glyphs, setGlyphs] = useState<string[]>(props.glyphs);

  useEffect(() => {
    setGlyphs(props.glyphs);
  }, [props.glyphs]);

  const addGlyph = (glyph: string) => {
    if (glyphs.includes(glyph)) {
      return;
    }

    setGlyphs([...glyphs, glyph]);
  };

  const removeGlyph = (glyph: string) => {
    setGlyphs([...glyphs.filter((glyphCurrent) => glyphCurrent !== glyph)]);
  };

  return (
    <Modal
      id={props.id}
      cancelName="Не изменять"
      okName="Изменить"
      title={`Изменить глифы`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        props.onChange(glyphs);
        hideModal(props.id);
      }}
    >
      <div className={styles.input_container}>
        <div>Текущие</div>
        <div className={styles.glyphs_container}>
          {glyphs.map((glyph) => (
            <div
              className={styles.glyph}
              key={`current-${glyph}`}
              onClick={() => removeGlyph(glyph)}
            >
              <span className={styles.glyph_item}>
                {Glyphs[glyph as keyof typeof Glyphs]}
              </span>
            </div>
          ))}
        </div>
        <div>Список</div>
        <div className={styles.glyphs_container}>
          {GLYPHS_LIST.map((el) => (
            <div
              className={styles.glyph}
              key={`all-${el.value}`}
              onClick={() => addGlyph(el.value)}
            >
              <span className={styles.glyph_item}>{el.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddTagsModal;
