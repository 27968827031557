import styles from "./item.module.scss";
import React from "react";
import { Move } from "chess.js";

type ItemProps = {
  move: Move;
  moveNumber: number;
  isFirstLineMove: boolean;
  isActive: boolean;
  onClick?: () => void;
};

const DummyItem = (props: ItemProps) => {
  const getMoveNumber = () => {
    if (props.move.color === "w") {
      return `${props.moveNumber}.`;
    }

    if (props.isFirstLineMove) {
      return `${props.moveNumber}. ..`;
    }
  };

  const firstNotationLetter =
    ["O-O", "O-O-O"].includes(props.move.san) || props.move.piece === "p"
      ? undefined
      : props.move.piece;
  const tileNotation = firstNotationLetter
    ? props.move.san.slice(1)
    : props.move.san;

  const moveNumber = getMoveNumber();

  return (
    <div
      className={`${styles.move} ${props.isActive ? styles.move_active : ""}`}
      onClick={() => props.onClick && props.onClick()}
    >
      {moveNumber && <span className={styles.move_number}>{moveNumber}</span>}
      {firstNotationLetter && (
        <span className={styles.move_first_letter}>
          {firstNotationLetter.toLowerCase()}
        </span>
      )}
      <span className={styles.move_tile}>{tileNotation}</span>
    </div>
  );
};

export default DummyItem;
