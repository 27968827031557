import { GetTypical } from "../../types/get-typical";
import { getAxiosInstance } from "../axios-instance";

export async function getTypical(typicalId: number): Promise<GetTypical> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ typical: GetTypical }>(`/api/v1/typicals/${typicalId}`)
      .then((data) => resolve(data.data.typical))
      .catch((error) => reject(error));
  });
}
