import React from "react";
import { GetTechnical } from "../../../common/types/get-technical";
import { GetTechnicalGroup } from "../../../common/types/get-technical-group";
import { Role } from "../../../common/types/role";
import CreateTechnicalModal from "../../modal/create-technical.modal";
import DeleteTechnicalGroupModal from "../../modal/delete-group.modal";
import EditTechnicalGroupModal from "../../modal/edit-group.modal";
import styles from "./menu-technical-group.module.scss";
import MenuTechnicalItem from "./menu-technical-item";
import { checkPermission } from "../../../stores/user";
import { showModal } from "../../../stores/modal";

type MenuTechnicalGroupProps = {
  group: GetTechnicalGroup;
  isOpened: boolean;
  toggleGroup: () => void;
  updateMenu: () => void;
};

const MenuTechnicalGroup = (props: MenuTechnicalGroupProps) => {
  return (
    <>
      <EditTechnicalGroupModal
        id={`edit-technical-group-modal-${props.group.id}`}
        group={props.group}
        onClose={props.updateMenu}
      ></EditTechnicalGroupModal>
      <DeleteTechnicalGroupModal
        id={`delete-technical-group-modal-${props.group.id}`}
        group={props.group}
        onClose={props.updateMenu}
      ></DeleteTechnicalGroupModal>
      <CreateTechnicalModal
        id={`create-technical-modal-${props.group.id}`}
        group={props.group}
        onClose={props.updateMenu}
      ></CreateTechnicalModal>
      <div className={styles.group_item}>
        <div className={styles.group_item_info} onClick={props.toggleGroup}>
          <div className={styles.group_name}>
            <span>{props.group.name}</span>
          </div>
          <span
            className={`bi bi-${props.isOpened ? "chevron-down" : "chevron-left"}`}
            title={props.isOpened ? "Закрыть варианты" : "Открыть варианты"}
          ></span>
        </div>
        <div>
          {checkPermission(Role.TECHNICAL_CHANGE) && (
            <>
              <span
                className={`bi bi-file-earmark-text ${styles.group_item_button}`}
                title="Изменить группу"
                onClick={() => {
                  showModal(`edit-technical-group-modal-${props.group.id}`);
                }}
              ></span>
              <span
                className={`bi bi-file-earmark-x  ${styles.group_item_button}`}
                title="Удалить группу"
                onClick={() => {
                  showModal(`delete-technical-group-modal-${props.group.id}`);
                }}
              ></span>
              <span
                className={`bi bi-file-earmark-plus  ${styles.group_item_button}`}
                title="Добавить позицию"
                onClick={() => {
                  showModal(`create-technical-modal-${props.group.id}`);
                }}
              ></span>
            </>
          )}
        </div>
        {props.isOpened && (
          <div className={styles.technical_items}>
            {props.group.technicals.map((technical: GetTechnical) => {
              return (
                <MenuTechnicalItem
                  key={`technical-item-${technical.id}`}
                  technical={technical}
                  updateMenu={props.updateMenu}
                ></MenuTechnicalItem>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MenuTechnicalGroup;
