import { GetTechnicalGroup } from "../../types/get-technical-group";
import { getAxiosInstance } from "../axios-instance";

export async function editTechnicalGroup(
  groupId: number,
  name: string,
): Promise<GetTechnicalGroup> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .put<{ technical_group: GetTechnicalGroup }>(
        `/api/v1/technical-group/${groupId}`,
        {
          name,
        },
      )
      .then((data) => resolve(data.data.technical_group))
      .catch((error) => reject(error));
  });
}
