import React from "react";
import { useNavigate } from "react-router-dom";
import { GetVariantAnalyze } from "../../../common/types/get-variant-analyze";
import { Role } from "../../../common/types/role";
import { Sections } from "../../../common/types/sections";
import DeleteVariantModal from "../../modal/delete-variant.modal";
import EditVariantModal from "../../modal/edit-variant.modal";
import styles from "./menu-variant-analyze.module.scss";
import { checkPermission, updateUserInfo } from "../../../stores/user";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

type MenuVariantAnalyzeProps = {
  variant: GetVariantAnalyze;
  updateMenu: () => void;
};

const MenuVariantAnalyze = (props: MenuVariantAnalyzeProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.item}>
      <DeleteVariantModal
        variant={props.variant}
        id={`delete-variant-modal-${props.variant.id}`}
        onClose={props.updateMenu}
      ></DeleteVariantModal>
      <EditVariantModal
        variant={props.variant}
        id={`edit-variant-modal-${props.variant.id}`}
        onClose={props.updateMenu}
      ></EditVariantModal>
      <div className={styles.item_info}>
        <div className={styles.item_name}>
          <span
            onClick={() => {
              updateUserInfo({
                section: Sections.ANALYZE,
                variant_id: props.variant.id,
              });
              navigate(`/analyze/${props.variant.id}`);
              hideMenu();
            }}
          >
            {props.variant.name}
          </span>
          {props.variant.color && (
            <span
              className={styles.variant_color}
              style={{ backgroundColor: props.variant.color }}
            ></span>
          )}
        </div>
      </div>
      {checkPermission(Role.VARIANT_CHANGE) && (
        <div>
          <span
            id={styles.edit_variant}
            className="bi bi-file-earmark-text"
            title="Изменить вариант"
            onClick={() => {
              showModal(`edit-variant-modal-${props.variant.id}`);
            }}
          ></span>
          <span
            id={styles.delete_variant}
            className="bi bi-file-earmark-x"
            title="Удалить вариант"
            onClick={() => {
              showModal(`delete-variant-modal-${props.variant.id}`);
            }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default MenuVariantAnalyze;
