import React, { useState } from "react";
import Modal from ".";
import { createTechncialGroup } from "../../common/api/technical-group/create-technical-group";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import styles from "./modal.module.scss";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateTechnicalGroupModalProps = {
  id: string;
  onClose: () => void;
};

const CreateTechnicalGroupModal = (props: CreateTechnicalGroupModalProps) => {
  const [technicalGroupName, setTechnicalGroupName] = useState("");

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Создание новой группы`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        if (technicalGroupName.length < 3) {
          showDangerAlert("Название группы должно содержать минимум 3 символа");
          return;
        }

        createTechncialGroup(technicalGroupName)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Группа успешно создана");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать группу");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название группы</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalGroupName}
          onChange={(value) => {
            setTechnicalGroupName(String(value));
          }}
          placeholder="Введите название варианта"
        ></Input>
      </div>
    </Modal>
  );
};

export default CreateTechnicalGroupModal;
