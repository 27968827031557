import { GetCount } from "../../types/get-count";
import { getAxiosInstance } from "../axios-instance";

export async function getCounts(): Promise<GetCount[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ counts: GetCount[] }>(`/api/v1/counts/list`)
      .then((data) => resolve(data.data.counts))
      .catch((error) => reject(error));
  });
}
