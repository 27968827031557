import styles from "./context-menu.module.scss";
import React, { useEffect } from "react";
import { Role } from "../../common/types/role";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { checkPermission } from "../../stores/user";

type ContextMenuProps = {
  accessKey: number[];
  position: string;
  clientX: number;
  clientY: number;
  onClose: () => void;
  onComment: () => void;
  onUp: () => void;
  onRemove: () => void;
  onTag: () => void;
};

const ContextMenu = (props: ContextMenuProps) => {
  const menuElement: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    const eventHandler = (event: MouseEvent) => {
      event.preventDefault();

      if (menuElement.current) {
        for (
          let index = 0;
          index < menuElement.current.children.length;
          index++
        ) {
          const element = menuElement.current.children[index];

          if (event.target === element) {
            return;
          }
        }
      }

      props.onClose();
    };

    window.addEventListener("mouseup", eventHandler);

    return () => {
      window.removeEventListener("mouseup", eventHandler);
    };
  }, [menuElement, props]);

  return (
    <div
      className={styles.menu_wrapper}
      ref={menuElement}
      style={{
        top: props.clientY - 20,
        left: props.clientX - 144 + 20,
      }}
    >
      {checkPermission(Role.VARIANT_CHANGE) && (
        <>
          <div
            className={styles.menu_item}
            onClick={() => {
              props.onComment();
            }}
          >
            Комментировать
          </div>
          <div
            className={styles.menu_item}
            onClick={() => {
              props.onTag();
            }}
          >
            Изменить глифы
          </div>
        </>
      )}
      {props.accessKey.length !== 1 && (
        <div
          className={styles.menu_item}
          onClick={() => {
            props.onUp();
          }}
        >
          Вверх
        </div>
      )}
      <CopyToClipboard text={props.position || ""}>
        <div
          className={styles.menu_item}
          onClick={() => {
            props.onClose();
          }}
        >
          Копировать
        </div>
      </CopyToClipboard>
      {checkPermission(Role.VARIANT_CHANGE) && props.accessKey[0] !== 0 && (
        <div
          className={styles.menu_item}
          onClick={() => {
            props.onRemove();
          }}
        >
          Удалить
        </div>
      )}
    </div>
  );
};

export default ContextMenu;
