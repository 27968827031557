import styles from "./modal.module.scss";
import React, { ReactNode } from "react";
import Button from "../shared/button/button";
import { ButtonSize } from "../shared/button/types/size";

type ModalProps = {
  id: string;
  children: ReactNode;
  cancelName: string;
  okName: string;
  title: string;
  onCancel: () => void;
  onOk: () => void;
};

const Modal = (props: ModalProps) => {
  return (
    <div className="modal fade" id={props.id}>
      <div className="modal-dialog">
        <div id={styles.modal_content} className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{props.title}</h1>
            <button
              type="button"
              className="btn-close"
              title="Закрыть"
              onClick={() => props.onCancel()}
            ></button>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">
            <Button
              title={props.cancelName}
              text={props.cancelName}
              size={ButtonSize.ORDINARY}
              onClick={() => props.onCancel()}
            ></Button>
            <Button
              title={props.okName}
              text={props.okName}
              size={ButtonSize.ORDINARY}
              onClick={() => props.onOk()}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
