import React, { useState } from "react";
import Modal from ".";
import styles from "./modal.module.scss";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import { createCount } from "../../common/api/count/create-count";
import Textarea from "../shared/textarea/textarea";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateCountModalProps = {
  id: string;
  onClose: () => void;
};

const CreateCountModal = (props: CreateCountModalProps) => {
  const [countName, setCountName] = useState("");
  const [countPgn, setCountPgn] = useState("");

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Создание партии`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        createCount(countName, countPgn)
          .then(() => {
            hideModal(props.id);
            props.onClose();
            showSuccessAlert("Партия успешно создана");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать партию");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название партии</label>
        <Input
          type={InputTypes.TEXT}
          value={countName}
          onChange={(value) => setCountName(String(value))}
          placeholder="Введите название позиции"
        ></Input>
        <label>Pgn партии</label>
        <Textarea
          value={countPgn}
          onChange={(value) => setCountPgn(String(value))}
          placeholder="Введите pgn позиции"
        ></Textarea>
      </div>
    </Modal>
  );
};

export default CreateCountModal;
