import React, { useState, useEffect } from "react";
import { Orientation } from "../../common/types/orientation";
import styles from "./board.module.scss";
import { LastMove } from "./types/last-move";
import { Promotions } from "./types/promotions";
import { ChessHelper } from "../../common/utils/chess";
import SquareComponent from "./square/square";
import Promotion from "./promotion/promotion";
import { Piece, Square, SQUARES } from "chess.js";
import { Board as BoardModel } from "./types/board";
import { cloneDeep } from "lodash";

type BoardProps = {
  id: string;
  orientation: Orientation;
  position: string;
  lastMove?: LastMove;
  onMove: (
    from: Square,
    to: Square,
    promotion: Promotions,
    finish: () => void,
  ) => void;
};

const Board = (props: BoardProps) => {
  const [squares, setSquares] = useState<Square[]>(SQUARES);
  const [board, setBoard] = useState<BoardModel>([]);
  const [movableSquares, setMovableSquares] = useState<Square[]>([]);
  const [availableSquares, setAvailableSquares] = useState<Square[]>([]);
  const [floatFigure, setFloatFigure] = useState<Piece | undefined>(undefined);
  const [from, setFrom] = useState<Square | undefined>(undefined);
  const [to, setTo] = useState<Square | undefined>(undefined);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const boardEl = document.getElementById(props.id);
  const boardHeight = boardEl?.offsetHeight || 0;
  const boardWidth = boardEl?.offsetWidth || 0;
  const boardMarginTop = 60;
  const boardMarginLeft = 10;

  useEffect(() => {
    updatePosition();
  }, [props.position, props.orientation]);

  const updatePosition = () => {
    const chess = new ChessHelper();
    chess.init(props.position);

    const newBoard = cloneDeep(chess.board());
    const availableMoves = chess.moves(from);
    const movableSquares = from ? [] : availableMoves.map((move) => move.from);
    if (props.orientation === Orientation.BLACK) {
      newBoard.forEach((row) => row.reverse());
      newBoard.reverse();
    }

    setSquares((state) => {
      // тут костыль, так как почему-то иногда некорректно ревертится список squares
      const newState = cloneDeep(state);
      if (props.orientation === Orientation.WHITE && newState[0] !== "a8") {
        return newState.reverse();
      }

      if (props.orientation === Orientation.BLACK && newState[0] !== "h1") {
        return newState.reverse();
      }

      return newState;
    });

    setBoard(newBoard);
    setMovableSquares(movableSquares);
    setAvailableSquares([]);
  };

  const onClick = (square: Square) => {
    const chess = new ChessHelper();
    chess.init(props.position);

    if (!from) {
      setFrom(square);
      setAvailableSquares(chess.moves(square).map((move) => move.to));

      return;
    }

    if (!chess.validate(from, square) || from === square) {
      finish();
      return;
    }

    setTo(square);

    const row = Number(square[1]);
    const piece = chess.getPiece(from);

    if (
      (piece.color === "w" && piece.type === "p" && row === 8) ||
      (piece.color === "b" && piece.type === "p" && row === 1)
    ) {
      return;
    }

    props.onMove(from, square, "q", () => finish());
  };

  const handleMouseMove = (event: MouseEvent) => {
    setX(event.clientX - boardWidth / (2 * 8) - boardMarginLeft);
    setY(event.clientY - boardHeight / (2 * 8) - boardMarginTop);
  };

  const onStartDragProcess = (
    square: Square,
    clientX: number,
    clientY: number,
  ) => {
    if (from) {
      return;
    }

    const chess = new ChessHelper();
    chess.init(props.position);

    window.addEventListener("mousemove", handleMouseMove);

    const x = clientX - boardWidth / (2 * 8) - boardMarginLeft;
    const y = clientY - boardHeight / (2 * 8) - boardMarginTop;
    const availableMoves = chess.moves(square);
    const availableSquares = availableMoves.map((move) => move.to);
    const floatFigure = chess.getPiece(square);
    setFrom(square);
    setAvailableSquares(availableSquares);
    setFloatFigure(floatFigure);
    setX(x);
    setY(y);
  };

  const onMouseUp = () => {
    if (!from) {
      return;
    }

    const chess = new ChessHelper();
    chess.init(props.position);

    const currentX = x + boardWidth / (2 * 8);
    const currentY = y + boardHeight / (2 * 8);
    const rows = ["8", "7", "6", "5", "4", "3", "2", "1"];
    const cols = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (props.orientation === Orientation.BLACK) {
      rows.reverse();
      cols.reverse();
    }

    const row = rows[Math.floor(currentY / (boardHeight / 8))];
    const col = cols[Math.floor(currentX / (boardWidth / 8))];
    const square = `${col}${row}` as Square;

    if (!chess.validate(from, square)) {
      finish();
      return;
    }

    setTo(square);

    window.removeEventListener("mousemove", handleMouseMove);

    const piece = chess.getPiece(from);

    if (
      (piece.color === "w" && piece.type === "p" && Number(row) === 8) ||
      (piece.color === "b" && piece.type === "p" && Number(row) === 1)
    ) {
      return;
    }

    props.onMove(from, square, "q", () => finish());
  };

  const finish = () => {
    const chess = new ChessHelper();
    chess.init(props.position);

    setFrom(undefined);
    setTo(undefined);
    setFloatFigure(undefined);

    const availableMoves = chess.moves();
    setMovableSquares(availableMoves.map((move) => move.from));
    setAvailableSquares([]);
  };

  const setPromotion = (promotion: Promotions) => {
    if (!from || !to) {
      return;
    }

    props.onMove(from, to, promotion, () => finish());
  };

  return (
    <div id={props.id} className={styles.board}>
      {board.map((row, indexRow) => {
        return (
          <div key={indexRow} className={styles.row}>
            {row.map((figure, indexSquare) => {
              const square = squares[indexRow * 8 + indexSquare];

              return (
                <div key={indexSquare} className={styles.square}>
                  <SquareComponent
                    square={square}
                    editable={false}
                    figure={floatFigure && square === from ? null : figure}
                    dummy={false}
                    active={
                      props.lastMove?.from === square ||
                      props.lastMove?.to === square
                    }
                    movable={movableSquares.includes(square)}
                    availableForMove={availableSquares.includes(square)}
                    onStartDragProcess={(x: number, y: number) =>
                      onStartDragProcess(square, x, y)
                    }
                    onClick={() => onClick(square)}
                  ></SquareComponent>
                </div>
              );
            })}
          </div>
        );
      })}
      {floatFigure && (
        <img
          id={styles.float_figure}
          draggable={false}
          onMouseUp={() => onMouseUp()}
          style={{ top: y, left: x }}
          src={`${process.env.PUBLIC_URL}/chesspieces/${floatFigure.color}${floatFigure.type.toUpperCase()}.svg`}
        />
      )}
      {to && (
        <Promotion
          orientation={props.orientation}
          square={to}
          onClick={(promotion: Promotions) => {
            setPromotion(promotion);
          }}
        ></Promotion>
      )}
    </div>
  );
};

export default Board;
