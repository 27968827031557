import { GetOpeningPractice } from "../../types/get-opening-practice";
import { getAxiosInstance } from "../axios-instance";

export async function getOpeningsForPractice(): Promise<GetOpeningPractice[]> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ openings: GetOpeningPractice[] }>(`/api/v1/openings/practice`)
      .then((data) => resolve(data.data.openings))
      .catch((error) => reject(error));
  });
}
