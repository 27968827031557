import React, { useState } from "react";
import Modal from ".";
import { GetTechnicalGroup } from "../../common/types/get-technical-group";
import { TechnicalResult } from "../../common/types/technical-result";
import { createTechnical } from "../../common/api/technical/create-technical";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import styles from "./modal.module.scss";
import Select from "../shared/select/select";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateTechnicalModalProps = {
  id: string;
  group: GetTechnicalGroup;
  onClose: () => void;
};

const CreateTechnicalModal = (props: CreateTechnicalModalProps) => {
  const [technicalName, setTechnicalName] = useState("");
  const [technicalPgn, setTechnicalPgn] = useState("");
  const [technicalResult, setTechnicalResult] = useState(TechnicalResult.WIN);

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Позиция для группы - ${props.group.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        createTechnical(
          props.group.id,
          technicalName,
          technicalPgn,
          technicalResult,
        )
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Вариант успешно создан");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать вариант");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название позиции</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalName}
          onChange={(value) => setTechnicalName(String(value))}
          placeholder="Введите название позиции"
        ></Input>
        <label>Pgn позиции</label>
        <Input
          type={InputTypes.TEXT}
          value={technicalPgn}
          onChange={(value) => setTechnicalPgn(String(value))}
          placeholder="Введите pgn позиции"
        ></Input>
        <label>Цель позиции</label>
        <Select
          defaultValue={technicalResult}
          options={[
            { value: TechnicalResult.WIN, label: "На выигрыш" },
            { value: TechnicalResult.DRAW, label: "На ничью" },
          ]}
          onChange={(value) => {
            setTechnicalResult(value as TechnicalResult);
          }}
        ></Select>
      </div>
    </Modal>
  );
};

export default CreateTechnicalModal;
