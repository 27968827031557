import React from "react";
import Modal from ".";
import { GetOpeningAnalyze } from "../../common/types/get-opening-analyze";
import { deleteOpening } from "../../common/api/openings/delete-opening";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteOpeningModalProps = {
  id: string;
  opening: GetOpeningAnalyze;
  onClose: () => void;
};

const DeleteOpeningModal = (props: DeleteOpeningModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить дебют - ${props.opening.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        deleteOpening(props.opening.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Дебют успешно удален");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить дебют");
          });
      }}
    >
      <span>Вы точно хотите удалить дебют?</span>
    </Modal>
  );
};

export default DeleteOpeningModal;
