import React from "react";
import Modal from ".";
import { GetCount } from "../../common/types/get-count";
import { deleteCount } from "../../common/api/count/delete-count";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteCountModalProps = {
  id: string;
  count: GetCount;
  onClose: () => void;
};

const DeleteCountModal = (props: DeleteCountModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить партию - ${props.count.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        deleteCount(props.count.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Партия успешно удалена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить партию");
          });
      }}
    >
      <span>Вы точно хотите удалить партию?</span>
    </Modal>
  );
};

export default DeleteCountModal;
