import React from "react";
import Modal from ".";
import { GetVariantAnalyze } from "../../common/types/get-variant-analyze";
import { deleteVariant } from "../../common/api/variants/delete-variant";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteVariantModalProps = {
  id: string;
  variant: GetVariantAnalyze;
  onClose: () => void;
};

const DeleteVariantModal = (props: DeleteVariantModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить вариант - ${props.variant.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        deleteVariant(props.variant.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Вариант успешно удален");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить вариант");
          });
      }}
    >
      <span>Вы точно хотите удалить вариант?</span>
    </Modal>
  );
};

export default DeleteVariantModal;
