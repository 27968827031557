import React, { SyntheticEvent } from "react";
import { isMobile } from "detect-touch-device";
import styles from "./square.module.scss";
import { Square } from "chess.js";
import { Figure } from "../types/figure";

type SquareProps = {
  square: Square;
  figure: Figure | null;
  dummy: boolean;
  active: boolean;
  movable: boolean;
  editable: boolean;
  availableForMove: boolean;
  onStartDragProcess?: (x: number, y: number) => void; // для устройств без touchscreen
  onClick?: () => void; // для устройств с touchscreen
};
const SquareComponent = (props: SquareProps) => {
  const onClick = (event: SyntheticEvent) => {
    if (
      !props.editable &&
      ((!props.availableForMove && !props.movable) || !isMobile)
    ) {
      return;
    }

    event.preventDefault();

    if (props.onClick) {
      props.onClick();
    }
  };

  const onMouseDown = (event: React.MouseEvent) => {
    if ((!props.availableForMove && !props.movable) || isMobile) {
      return;
    }

    if (props.onStartDragProcess) {
      props.onStartDragProcess(event.clientX, event.clientY);
    }
  };

  const getClassWrapper = () => {
    let availableClass = "";
    if (props.availableForMove) {
      availableClass = `${styles.available}`;
    }

    if (props.active) {
      return `${styles.active} ${availableClass}`;
    }

    if (props.dummy || !props.movable) {
      return `${styles.dummy} ${availableClass}`;
    }

    return availableClass;
  };

  const getFigure = () => {
    if (!props.figure) {
      return null;
    }

    return `${props.figure.color}${props.figure.type.toUpperCase()}`;
  };

  const figure = getFigure();

  return (
    <div
      id={styles[`square_${props.square}`]}
      className={getClassWrapper()}
      onClick={(event) => onClick(event)}
      onMouseDown={(event) => onMouseDown(event)}
    >
      {figure && (
        <div className={styles.figure_wrapper}>
          <img
            id={`figure_${props.square}`}
            draggable={false}
            className={styles.figure}
            src={`${process.env.PUBLIC_URL}/chesspieces/${figure}.svg`}
          />
        </div>
      )}
    </div>
  );
};

export default SquareComponent;
