import { createEffect } from "effector";
import { UserState } from "../../types/user-state";
import { getAxiosInstance } from "../axios-instance";

export const $updateUserInfoFx = createEffect<UserState, void>();

$updateUserInfoFx.use(async (data) => {
  await updateUserInfo(data);
});

async function updateUserInfo(userState: UserState): Promise<void> {
  try {
    await getAxiosInstance().put(`/api/v1/users`, userState);
  } catch (error) {
    throw new Error("Failed to get user info");
  }
}
