import React from "react";
import Modal from ".";
import { hideModal } from "../../stores/modal";

type DeleteMovesModalProps = {
  id: string;
  onDelete: () => void;
};

const DeleteMovesModal = (props: DeleteMovesModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удаление ходов`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        props.onDelete();
        hideModal(props.id);
      }}
    >
      <span>Вы точно хотите удалить ходы?</span>
    </Modal>
  );
};

export default DeleteMovesModal;
