export enum Role {
  OPENING_CHANGE = "opening_change",
  OPENING_VIEW = "opening_view",
  VARIANT_CHANGE = "variant_change",
  VARIANT_VIEW = "variant_view",
  TYPICAL_CHANGE = "typical_change",
  TYPICAL_VIEW = "typical_view",
  TECHNICAL_CHANGE = "technical_change",
  TECHNICAL_VIEW = "technical_view",
  LINE_VIEW = "line_view",
}
