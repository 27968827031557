import { Chess, Color, PieceSymbol, Square, validateFen } from "chess.js";

export class ChessHelper {
  private static instance: ChessHelper;
  private chess: Chess;
  constructor() {
    this.chess = new Chess();
  }

  init(fen: string) {
    this.chess.clear();
    this.chess.load(fen);
  }

  board() {
    return this.chess.board();
  }

  moves(square?: Square) {
    return this.chess.moves({ verbose: true, square });
  }

  history() {
    return this.chess.history({ verbose: true });
  }

  lastMove() {
    return this.chess.history({ verbose: true }).pop();
  }

  loadPgn(pgn = "") {
    return this.chess.loadPgn(pgn);
  }

  validateFen(fen: string) {
    const { ok } = validateFen(fen);

    return ok;
  }

  turn() {
    return this.chess.turn();
  }

  moveNumber() {
    return this.chess.moveNumber();
  }

  remove(square: Square) {
    return this.chess.remove(square);
  }

  put(square: Square, type: PieceSymbol, color: Color) {
    return this.chess.put({ type, color }, square);
  }

  validate(from: string, to: string, promotion = "q") {
    try {
      this.chess.move({
        from,
        to,
        promotion,
      });

      this.chess.undo();

      return true;
    } catch {
      return false;
    }
  }

  getPiece(square: Square) {
    return this.chess.get(square);
  }

  loadFenWithoutValidation(fen: string) {
    this.chess.load(fen, { skipValidation: true });
  }

  move(from: string, to: string, promotion: PieceSymbol | string = "q") {
    try {
      const newMove = this.chess.move({
        from,
        to,
        promotion,
      });

      return newMove;
    } catch (error) {
      return null;
    }
  }

  moveFen(fen: string) {
    try {
      const newMove = this.chess.move(fen);

      return newMove;
    } catch (error) {
      return null;
    }
  }

  setComment(comment: string) {
    this.chess.setComment(comment);
  }

  getFen() {
    return this.chess.fen();
  }

  getMoveNumber() {
    return this.chess.moveNumber();
  }
}
