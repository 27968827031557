import { getAxiosInstance } from "../axios-instance";

export async function deleteCount(countId: number): Promise<void> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .delete(`/api/v1/counts/${countId}`)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}
