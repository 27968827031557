import React from "react";
import { GetOpeningAnalyze } from "../../../common/types/get-opening-analyze";
import { GetVariantAnalyze } from "../../../common/types/get-variant-analyze";
import { Orientation } from "../../../common/types/orientation";
import { Role } from "../../../common/types/role";
import CreateVariantModal from "../../modal/create-variant.modal";
import DeleteOpeningModal from "../../modal/delete-opening.modal";
import EditOpeningModal from "../../modal/edit-opening.modal";
import styles from "./menu-opening-analyze.module.scss";
import MenuVariantAnalyze from "./menu-variant-analyze";
import { checkPermission } from "../../../stores/user";
import { showModal } from "../../../stores/modal";

type MenuOpeningAnalyzeProps = {
  opening: GetOpeningAnalyze;
  isOpened: boolean;
  toggleOpening: () => void;
  updateMenu: () => void;
};

const MenuOpeningAnalyze = (props: MenuOpeningAnalyzeProps) => {
  const isWhite = props.opening.side === Orientation.WHITE;

  return (
    <>
      <EditOpeningModal
        opening={props.opening}
        onClose={props.updateMenu}
        id={`edit-opening-modal-${props.opening.id}`}
      ></EditOpeningModal>
      <DeleteOpeningModal
        opening={props.opening}
        onClose={props.updateMenu}
        id={`delete-opening-modal-${props.opening.id}`}
      ></DeleteOpeningModal>
      <CreateVariantModal
        opening={props.opening}
        onClose={props.updateMenu}
        id={`create-variant-modal-${props.opening.id}`}
      ></CreateVariantModal>
      <div className={styles.item}>
        <div className={styles.item_info} onClick={props.toggleOpening}>
          <div className={styles.item_name}>
            <span
              className={`bi bi-${
                isWhite ? "circle-fill" : "circle"
              } ${styles.opening_side}`}
              title={isWhite ? "За белых" : "За черных"}
            ></span>
            <div className={styles.opening_name}>
              <span>{props.opening.name}</span>
            </div>
          </div>
          <span
            className={`bi bi-${props.isOpened ? "chevron-down" : "chevron-left"}`}
            title={props.isOpened ? "Закрыть варианты" : "Открыть варианты"}
          ></span>
        </div>
        <div className={styles.item_buttons}>
          {checkPermission(Role.OPENING_CHANGE) && (
            <>
              <span
                id={styles.edit_opening_button}
                className="bi bi-file-earmark-text"
                title="Изменить дебют"
                onClick={() => {
                  showModal(`edit-opening-modal-${props.opening.id}`);
                }}
              ></span>
              <span
                id={styles.delete_opening_button}
                className="bi bi-file-earmark-x"
                title="Удалить дебют"
                onClick={() => {
                  showModal(`delete-opening-modal-${props.opening.id}`);
                }}
              ></span>
              <span
                id={styles.create_variant_button}
                className="bi bi-file-earmark-plus"
                title="Добавить вариант"
                onClick={() => {
                  showModal(`create-variant-modal-${props.opening.id}`);
                }}
              ></span>
            </>
          )}
        </div>
        {props.isOpened && (
          <div className={styles.variant_items}>
            {props.opening.variants.map((variant: GetVariantAnalyze) => {
              return (
                <MenuVariantAnalyze
                  key={`variant-item-${variant.id}`}
                  variant={variant}
                  updateMenu={props.updateMenu}
                ></MenuVariantAnalyze>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MenuOpeningAnalyze;
