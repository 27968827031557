import React, { useState, useRef } from "react";
import Topmenu from "../topmenu/topmenu";
import { Orientation } from "../../common/types/orientation";
import styles from "./position.module.scss";
import EditBoard from "../board/edit-board";
import Board from "../board/board";
import { DEFAULT_POSITION, Move, Square } from "chess.js";
import { Promotions } from "../board/types/promotions";
import Wrapper from "../shared/wrapper/wrapper";
import { LastMove } from "../board/types/last-move";
import DummyItem from "../items/dummy-item";
import { ChessHelper } from "../../common/utils/chess";

const PositionComponent = () => {
  const [isEdit, setIsEdit] = useState(true);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [startPosition, setStartPosition] = useState(DEFAULT_POSITION);
  const [orientation, setOrientation] = useState(Orientation.WHITE);
  const [lastMove, setLastMove] = useState<LastMove | undefined>(undefined);
  const [moves, setMoves] = useState<Move[]>([]);
  const [activeMove, setActiveMove] = useState(-1);

  const wrapperEl = useRef(null);

  const showNewMove = (newActiveMove: number, move?: Move) => {
    setPosition(move?.after || startPosition || position || DEFAULT_POSITION);
    setLastMove(
      move ? { from: move.from as Square, to: move.to as Square } : undefined,
    );
    setActiveMove(newActiveMove);
  };

  const moveLeft = () => {
    const newActiveMove = Math.max(-1, activeMove - 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const moveRight = () => {
    const newActiveMove = Math.min(moves.length - 1, activeMove + 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (moves.length === 0) {
      return;
    }

    switch (event.code) {
      case "ArrowLeft":
        moveLeft();
        return;
      case "ArrowRight":
        moveRight();
        return;
    }
  };

  const onMove = (
    from: Square,
    to: Square,
    promotion: Promotions,
    finish: () => void,
  ) => {
    const chess = new ChessHelper();
    chess.init(startPosition || DEFAULT_POSITION);
    for (let i = 0; i <= activeMove; i++) {
      const move = moves[i];
      chess.move(move.from, move.to, move.promotion);
    }

    const newMove = chess.move(from, to, promotion);
    if (!newMove) {
      finish();
      focus();
      return;
    }

    actualizeState(chess);
    finish();
    focus();
  };

  const actualizeState = (chess: ChessHelper) => {
    const moves = chess.history();
    const lastMove = moves[moves.length - 1];

    setMoves(moves);
    setActiveMove(moves.length - 1);
    setLastMove({ from: lastMove.from as Square, to: lastMove.to as Square });
    setPosition(chess.getFen());
  };

  const focus = () => {
    if (wrapperEl.current) {
      const el = wrapperEl.current as any;
      el.focus();
    }
  };

  return (
    <Wrapper
      isMovesOpened={true}
      isFooterOpened={false}
      inRef={wrapperEl}
      movesTitle={`Позиция`}
      footerTitle={`Анализ`}
      onKeyDown={(event) => eventHandler(event)}
      topmenu={<Topmenu hiddenMenuButton={true}></Topmenu>}
      menu={null}
      board={
        <>
          {isEdit && (
            <EditBoard
              id="chessboard-position-edit"
              orientation={orientation}
              onChangeFen={(fen: string) => setStartPosition(fen)}
            ></EditBoard>
          )}
          {!isEdit && (
            <Board
              id="chessboard-position"
              orientation={orientation}
              position={position}
              lastMove={lastMove}
              onMove={(
                from: Square,
                to: Square,
                promotion: Promotions,
                finish: () => void,
              ) => {
                onMove(from, to, promotion, finish);
              }}
            ></Board>
          )}
        </>
      }
      buttons={
        <>
          <span
            id={styles.edit_button}
            className={`bi bi-${isEdit ? "joystick" : "gear"}`}
            title={isEdit ? "Играть" : "Установить позицию"}
            onClick={() => {
              const newPosition = isEdit ? startPosition : DEFAULT_POSITION;
              setIsEdit(!isEdit);
              setLastMove(undefined);
              setMoves([]);
              setActiveMove(-1);
              setPosition(newPosition);
            }}
          ></span>
          <span
            id={styles.flip_button}
            className={`bi bi-arrow-down-up`}
            title="Развернуть доску"
            onClick={() => {
              setOrientation((orientation) =>
                orientation === Orientation.WHITE
                  ? Orientation.BLACK
                  : Orientation.WHITE,
              );
            }}
          ></span>
        </>
      }
      movesHeader={<span className={styles.move_header}>Анализ позиции</span>}
      movesPgn={
        <>
          {moves.map((move: Move, moveId: number) => {
            return (
              <DummyItem
                key={`dummy-item-${moveId}`}
                move={move}
                moveNumber={
                  moves[0].color === "w"
                    ? Math.ceil(moveId / 2) + 1
                    : Math.floor((moveId + 1) / 2) + 1
                }
                isFirstLineMove={moveId === 0}
                isActive={moveId === activeMove}
                onClick={() => {
                  const currentHistoryMove = moves[moveId];
                  setPosition(currentHistoryMove?.after || position);
                  setLastMove(
                    currentHistoryMove
                      ? {
                          from: currentHistoryMove.from as Square,
                          to: currentHistoryMove.to as Square,
                        }
                      : undefined,
                  );
                  setActiveMove(moveId);
                }}
              ></DummyItem>
            );
          })}
        </>
      }
      footer={null}
    ></Wrapper>
  );
};

export default PositionComponent;
