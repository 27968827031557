import { createStore, createEvent } from "effector";
import { SocketHelper } from "../sockets/socket";
import { DEFAULT_POSITION } from "chess.js";
import { GetBestLinesResponse } from "../common/types/get-best-lines-response";

export const $bestLines = createStore<GetBestLinesResponse[]>([]);
export const $addBestLines = createEvent<GetBestLinesResponse[]>();
export const $requestBestLines = createEvent<{
  fen: string;
  count: number;
  depth: number;
}>();

const position = createStore<string>(DEFAULT_POSITION);
position.on($requestBestLines, (_, data) => {
  const socket: SocketHelper = SocketHelper.getInstance();
  socket.connect();

  socket.getBestLines(data.fen, data.count, data.depth);

  return data.fen;
});
$bestLines.on($addBestLines, (_, data) => {
  const socket: SocketHelper = SocketHelper.getInstance();
  socket.disconnect();

  return data;
});
