import React from "react";
import Auth from "./components/auth/auth";
import Loader from "./components/loader/loader";
import Analyze from "./components/analyze/analyze";
import Practice from "./components/practice/practice";
import { useStoreMap } from "effector-react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Typical from "./components/typical/typical";
import Technical from "./components/technical/technical";
import VariantTypicals from "./components/variant-typicals/variant-typicals";
import SettingsComponent from "./components/settings/settings";
import PositionComponent from "./components/position/position";
import CountComponent from "./components/count/count";
import CustomAlert from "./components/alerts/alert";
import { $loading } from "./stores/main";
import { $userInfo } from "./stores/user";
import { getAuthToken } from "./common/utils/local-storage";

const App = () => {
  const loading = useStoreMap($loading, (state) => state);
  const userInfo = useStoreMap($userInfo, (state) => state);

  const router = createBrowserRouter([
    {
      path: "/analyze",
      element: <Analyze></Analyze>,
    },
    {
      path: "/technical",
      element: <Technical></Technical>,
    },
    {
      path: "/typical",
      element: <Typical></Typical>,
    },
    {
      path: "/variant-typicals",
      element: <VariantTypicals></VariantTypicals>,
    },
    {
      path: "/practice",
      element: <Practice></Practice>,
    },
    {
      path: "/settings",
      element: <SettingsComponent></SettingsComponent>,
    },
    {
      path: "/position",
      element: <PositionComponent></PositionComponent>,
    },
    {
      path: "/count",
      element: <CountComponent></CountComponent>,
    },
    {
      path: "/variant-typicals/:variantId",
      element: <VariantTypicals></VariantTypicals>,
    },
    {
      path: "/analyze/:variantId",
      element: <Analyze></Analyze>,
    },
    {
      path: "/technical/:technicalId",
      element: <Technical></Technical>,
    },
    {
      path: "/count/:countId",
      element: <CountComponent></CountComponent>,
    },
    {
      path: "/technical/:countId",
      element: <CountComponent></CountComponent>,
    },
    {
      path: "/typical/:typicalId",
      element: <Typical></Typical>,
    },
    {
      path: "/practice/:variantId",
      element: <Practice></Practice>,
    },

    {
      path: "*",
      element: <Navigate to={`/analyze`}></Navigate>,
    },
  ]);

  const authToken = getAuthToken();

  return (
    <>
      <CustomAlert></CustomAlert>
      {loading ? (
        <Loader></Loader>
      ) : authToken || userInfo?.id ? (
        <RouterProvider router={router} />
      ) : (
        <Auth></Auth>
      )}
    </>
  );
};

export default App;
