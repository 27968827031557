import { AnalyzeLine } from "../common/types/analyze-line";
import { createStore, createEvent } from "effector";
import { AnalyzeLineResponse } from "../common/types/analyze-line-response";
import { cloneDeep } from "lodash";
import { ChessHelper } from "../common/utils/chess";
import { SocketHelper } from "../sockets/socket";
import { DEFAULT_POSITION } from "chess.js";

const MAX_ANALYZE_LINES = 30;

export const $analyzeData = createStore<AnalyzeLine[]>([]);
export const $addData = createEvent<AnalyzeLineResponse[]>();
export const $startAnalyze = createEvent<string>();
export const $stopAnalyze = createEvent();
export const $updateAnalyze = createEvent<string>();
const position = createStore<string>(DEFAULT_POSITION);

position.on($startAnalyze, (_, fen) => {
  const socket: SocketHelper = SocketHelper.getInstance();
  socket.connect();

  socket.analyzeStart(fen);

  return fen;
});

position.on($updateAnalyze, (_, fen) => {
  const socket: SocketHelper = SocketHelper.getInstance();
  socket.analyzeUpdate(fen);

  return fen;
});

position.on($stopAnalyze, () => {
  const socket: SocketHelper = SocketHelper.getInstance();

  socket.analyzeStop();

  socket.disconnect();

  return DEFAULT_POSITION;
});

$analyzeData.on([$startAnalyze, $updateAnalyze, $stopAnalyze], () => {
  return [];
});

$analyzeData.on($addData, (state, data: AnalyzeLineResponse[]) => {
  try {
    const newState = cloneDeep(state);
    const chess = new ChessHelper();

    for (const analyzeLine of data) {
      if (!analyzeLine.moves) {
        continue;
      }

      chess.init(position.getState());
      const turn = chess.turn();
      const moveNumber = chess.moveNumber();

      if (newState.length >= MAX_ANALYZE_LINES) {
        newState.pop();
      }

      for (const move of analyzeLine.moves || []) {
        chess.move(move.from, move.to, move.promotion);
      }

      newState.unshift({
        moves: chess.history(),
        moveNumber,
        score: (analyzeLine.score || 0) * 0.01 * (turn === "w" ? 1 : -1),
        depth: analyzeLine.depth || 0,
        seldepth: analyzeLine.seldepth || 0,
      });
    }

    return newState;
  } catch {}
});
