import React from "react";
import { useNavigate } from "react-router-dom";
import { GetVariantPractice } from "../../../common/types/get-variant-practice";
import { Sections } from "../../../common/types/sections";
import styles from "./menu-variant-practice.module.scss";
import { updateUserInfo } from "../../../stores/user";
import { hideMenu } from "../../../stores/menu";

type MenuVariantPracticeProps = {
  variant: GetVariantPractice;
  updateMenu: () => void;
};

const MenuVariantPractice = (props: MenuVariantPracticeProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.item}>
      <div className={styles.item_info}>
        <div
          className={`${styles.item_name} ${props.variant.practice_count > 0 ? styles.item_active : ""}`}
        >
          <span
            title="Повторить вариант"
            onClick={() => {
              updateUserInfo({
                section: Sections.PRACTICE,
                variant_id: props.variant.id,
              });
              hideMenu();
              navigate(`/practice/${props.variant.id}`);
            }}
          >
            {props.variant.name}
          </span>
        </div>
        <span>({props.variant.practice_count})</span>
        {props.variant.color && (
          <span
            className={styles.variant_color}
            style={{ backgroundColor: props.variant.color }}
          ></span>
        )}
      </div>
    </div>
  );
};

export default MenuVariantPractice;
