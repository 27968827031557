import React, { useState, useEffect } from "react";
import MenuOpeningPractice from "./menu-opening-practice";
import { GetOpeningPractice } from "../../../common/types/get-opening-practice";
import { getOpeningsForPractice } from "../../../common/api/openings/get-openings-practice";
import { hideMenu } from "../../../stores/menu";
import styles from "./menu-practice.module.scss";

const MenuPractice = () => {
  const [openings, setOpenings] = useState<GetOpeningPractice[]>([]);
  const [openedOpeningId, setOpenedOpeningId] = useState<number>(0);

  useEffect(() => {
    getOpenings();
  }, []);

  const getOpenings = () => {
    getOpeningsForPractice()
      .then((openings) => {
        setOpenings(openings);
      })
      .catch(() => undefined);
  };

  return (
    <>
      <div id="menu" className={`offcanvas offcanvas-end`}>
        <div id="offcanvas-header" className={styles.offcanvas_header}>
          <span>Меню</span>
          <div>
            <span
              id={styles.exit_button}
              className="bi bi-arrow-right middle-button-icon"
              title="Выход из меню"
              onClick={() => {
                hideMenu();
              }}
            ></span>
          </div>
        </div>
        <div id="offcanvas-body" className={styles.offcanvas_body}>
          {openings.map((opening: GetOpeningPractice) => {
            return (
              <MenuOpeningPractice
                key={`opening-item-${opening.id}`}
                opening={opening}
                isOpened={opening.id === openedOpeningId}
                toggleOpening={() =>
                  setOpenedOpeningId(
                    opening.id === openedOpeningId ? 0 : opening.id,
                  )
                }
                updateMenu={() => getOpenings()}
              ></MenuOpeningPractice>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MenuPractice;
