import { GetLine } from "../../types/get-line";
import { getAxiosInstance } from "../axios-instance";

export async function getLine(variantIds: number[]): Promise<GetLine> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get<{ line: GetLine }>(`/api/v1/lines`, {
        params: {
          variant_ids: variantIds,
        },
      })
      .then((data) => resolve(data.data.line))
      .catch((error) => reject(error));
  });
}
