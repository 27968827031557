import { Settings } from "../../types/settings";
import { getAxiosInstance } from "../axios-instance";

export async function editSettings(
  body: Record<Settings, string | number>,
): Promise<void> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .put<void>(`/api/v1/settings`, body)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}
