import { GetCount } from "../../types/get-count";
import { getAxiosInstance } from "../axios-instance";

export async function createCount(
  name: string,
  pgn: string,
): Promise<GetCount> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ count: GetCount }>(`/api/v1/counts`, {
        name,
        pgn,
      })
      .then((data) => resolve(data.data.count))
      .catch((error) => reject(error));
  });
}
