import { Socket, io } from "socket.io-client";
import {
  getAuthToken,
  getRefreshProcessFlag,
  getRefreshToken,
  removeAuthToken,
} from "../common/utils/local-storage";
import { SocketEvents } from "../common/types/socket-events";
import { AnalyzeLineResponse } from "../common/types/analyze-line-response";
import { refreshProcess } from "../common/api/axios-instance";
import { GetBestLinesResponse } from "../common/types/get-best-lines-response";
import { $addData } from "../stores/analyze";
import { $addBestLines } from "../stores/best-line";

const REQUEST_REPEAT_INTERVAL = 1000;

type SocketResponse = {
  id: string;
  data: AnalyzeLineResponse[];
};
export class SocketHelper {
  private static instance: SocketHelper;
  private socket: Socket;
  private id: string;
  private constructor() {
    this.socket = this.createSocketInstance();
  }

  static getInstance() {
    if (!SocketHelper.instance) {
      SocketHelper.instance = new SocketHelper();
    }

    return SocketHelper.instance;
  }

  connect() {
    this.socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        this.socket.connect();
      }
    });

    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  getBestLines(fen: string, count: number, depth: number) {
    this.exceptionHandler(() => {
      this.getBestLines(fen, count, depth);
    });

    this.socket.on(SocketEvents.BEST_LINES, (data: GetBestLinesResponse[]) => {
      $addBestLines(data);
      this.socket.off(SocketEvents.BEST_LINES);
    });

    this.socket.emit(SocketEvents.GET_BEST_LINES, { count, depth, fen });
  }

  analyzeStart(fen: string) {
    this.exceptionHandler(() => {
      this.analyzeStart(fen);
    });

    this.socket.on(SocketEvents.ANALYZE_LINE, (data: SocketResponse) => {
      this.id = data.id;
      $addData(data.data);
    });

    this.socket.emit(SocketEvents.ANALYZE_POSITION_START, { fen });
  }

  analyzeUpdate(fen: string) {
    this.exceptionHandler(() => {
      this.analyzeStart(fen);
    });

    this.socket.on(SocketEvents.ANALYZE_POSITION_UPDATE, () => undefined);

    this.socket.emit(SocketEvents.ANALYZE_POSITION_UPDATE, {
      id: this.id,
      fen,
    });
  }

  analyzeStop() {
    this.socket.removeAllListeners();
    this.exceptionHandler(() => undefined);

    this.socket.on(SocketEvents.ANALYZE_POSITION_STOP, () => undefined);

    this.socket.emit(SocketEvents.ANALYZE_POSITION_STOP, { id: this.id });
  }

  private exceptionHandler(cb: () => void) {
    this.socket.on(SocketEvents.EXCEPTION, () => {
      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        removeAuthToken();
        return window.location.reload();
      }

      if (refreshToken && !getRefreshProcessFlag()) {
        refreshProcess(refreshToken, () => {
          return window.location.reload();
        });
      }

      const interval = setInterval(() => {
        if (!getRefreshProcessFlag()) {
          this.socket.disconnect();
          this.socket = this.createSocketInstance();
          this.connect();
          clearInterval(interval);
          cb();
        }
      }, REQUEST_REPEAT_INTERVAL);
    });
  }

  private createSocketInstance() {
    return io(String(process.env.REACT_APP_SOCKET_URL), {
      autoConnect: false,
      transports: ["websocket"],
      auth: {
        authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}
