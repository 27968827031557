import React, { useState } from "react";
import Modal from ".";
import { GetVariantTypical } from "../../common/types/get-variant-typical";
import styles from "./modal.module.scss";
import { InputTypes } from "../shared/input/types/input-types";
import Input from "../shared/input/input";
import { createTypical } from "../../common/api/typical/create-typical";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type CreateTypicalModalProps = {
  id: string;
  variant: GetVariantTypical;
  onClose: () => void;
};

const CreateTypicalModal = (props: CreateTypicalModalProps) => {
  const [typicalName, setTypicalName] = useState("");
  const [typicalPgn, setTypicalPgn] = useState("");

  return (
    <Modal
      id={props.id}
      cancelName="Не создавать"
      okName="Создать"
      title={`Типовая позиция вариант - ${props.variant.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        createTypical(props.variant.id, typicalName, typicalPgn)
          .then(() => {
            hideModal(props.id);
            props.onClose();
            showSuccessAlert("Позиция успешно создана");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось создать позицию");
          });
      }}
    >
      <div className={styles.input_container}>
        <label>Название типового варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={typicalName}
          onChange={(value) => setTypicalName(String(value))}
          placeholder="Введите название позиции"
        ></Input>
        <label>Pgn типового варианта</label>
        <Input
          type={InputTypes.TEXT}
          value={typicalPgn}
          onChange={(value) => setTypicalPgn(String(value))}
          placeholder="Введите pgn позиции"
        ></Input>
      </div>
    </Modal>
  );
};

export default CreateTypicalModal;
