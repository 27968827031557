import React, { useState, useEffect } from "react";
import { useStoreMap } from "effector-react";
import styles from "./settings.module.scss";
import { Settings } from "../../common/types/settings";
import { editSettings } from "../../common/api/settings/edit-settings";
import Topmenu from "../topmenu/topmenu";
import Button from "../shared/button/button";
import { ButtonSize } from "../shared/button/types/size";
import Input from "../shared/input/input";
import { InputTypes } from "../shared/input/types/input-types";
import { showSuccessAlert } from "../../stores/alert";
import { $userInfo } from "../../stores/user";

const settingsText: Record<string, string> = {
  [Settings.TECHNICAL_DEPTH]: "Глубина анализа технической позиции",
  [Settings.TECHNICAL_LINES_COUNT]:
    "Количество линий в анализе технической позиции",
  [Settings.TECHNICAL_MAX_DEVIATE]:
    "Максимальное отклонение в оценке технической позиции между линиями",
  [Settings.TYPICAL_DEPTH]: "Глубина анализа типичной позиции",
  [Settings.TYPICAL_LINES_COUNT]: "Количество линий в анализе типичной позиции",
  [Settings.TYPICAL_MAX_DEVIATE]:
    "Максимальное отклонение в оценке типичной позиции между линиями",
};

const settingsMin: Record<Settings, number> = {
  [Settings.TECHNICAL_DEPTH]: 5,
  [Settings.TECHNICAL_LINES_COUNT]: 1,
  [Settings.TECHNICAL_MAX_DEVIATE]: 0.1,
  [Settings.TYPICAL_DEPTH]: 5,
  [Settings.TYPICAL_LINES_COUNT]: 1,
  [Settings.TYPICAL_MAX_DEVIATE]: 0.1,
};

const settingsMax: Record<Settings, number> = {
  [Settings.TECHNICAL_DEPTH]: 40,
  [Settings.TECHNICAL_LINES_COUNT]: 5,
  [Settings.TECHNICAL_MAX_DEVIATE]: 0.5,
  [Settings.TYPICAL_DEPTH]: 40,
  [Settings.TYPICAL_LINES_COUNT]: 5,
  [Settings.TYPICAL_MAX_DEVIATE]: 0.5,
};

export const defaultSettings: Record<Settings, number> = {
  [Settings.TECHNICAL_DEPTH]: 20,
  [Settings.TECHNICAL_LINES_COUNT]: 3,
  [Settings.TECHNICAL_MAX_DEVIATE]: 0.3,
  [Settings.TYPICAL_DEPTH]: 20,
  [Settings.TYPICAL_LINES_COUNT]: 3,
  [Settings.TYPICAL_MAX_DEVIATE]: 0.3,
};

const SettingsComponent = () => {
  const [settings, setSettings] =
    useState<Record<Settings, string | number>>(defaultSettings);
  const userInfoSettings = useStoreMap($userInfo, (state) => state?.settings);

  useEffect(() => {
    setSettings(userInfoSettings || defaultSettings);
  }, [userInfoSettings]);

  const updateData = () => {
    if (!settings) {
      return;
    }

    editSettings(settings).then(() => {
      showSuccessAlert("Настройки успешно сохранены");
    });
  };

  const keys = [
    Settings.TECHNICAL_DEPTH,
    Settings.TECHNICAL_LINES_COUNT,
    Settings.TECHNICAL_MAX_DEVIATE,
    Settings.TYPICAL_DEPTH,
    Settings.TYPICAL_LINES_COUNT,
    Settings.TYPICAL_MAX_DEVIATE,
  ];

  return (
    <>
      <Topmenu hiddenMenuButton={true}></Topmenu>
      <div id={styles.container}>
        {keys.map((key) => {
          const title = settingsText[key];
          return (
            <div className={styles.line} key={key}>
              <label>{title}</label>
              <Input
                type={InputTypes.NUMBER}
                step={
                  [
                    Settings.TECHNICAL_MAX_DEVIATE,
                    Settings.TYPICAL_MAX_DEVIATE,
                  ].includes(key)
                    ? 0.01
                    : 1
                }
                min={settingsMin[key]}
                max={settingsMax[key]}
                value={settings ? settings[key] : 0}
                onChange={(value) => {
                  setSettings((state) => ({ ...state, [key]: value }));
                }}
              ></Input>
            </div>
          );
        })}
        <Button
          title="Сохранить"
          text="Сохранить"
          size={ButtonSize.ORDINARY}
          onClick={() => {
            updateData();
          }}
        ></Button>
      </div>
    </>
  );
};

export default SettingsComponent;
