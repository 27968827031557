import React from "react";
import { useNavigate } from "react-router-dom";
import { GetVariantTypical } from "../../../common/types/get-variant-typical";
import { Sections } from "../../../common/types/sections";
import CreateTypicalModal from "../../modal/create-typical.modal";
import styles from "./menu-variant-typical.module.scss";
import { updateUserInfo } from "../../../stores/user";
import { hideMenu } from "../../../stores/menu";
import { showModal } from "../../../stores/modal";

type MenuVariantTypicalProps = {
  variant: GetVariantTypical;
  updateMenu: () => void;
};

const MenuVariantTypical = (props: MenuVariantTypicalProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.item}>
      <CreateTypicalModal
        id={`create-typical-modal-${props.variant.id}`}
        variant={props.variant}
        onClose={props.updateMenu}
      ></CreateTypicalModal>
      <div className={styles.item_info}>
        <div
          className={`${styles.item_name} ${props.variant.typical_count > 0 ? styles.item_active : ""}`}
        >
          <span
            title="Повторить типовую позицию"
            onClick={() => {
              if (props.variant.typical_count === 0) {
                return;
              }
              updateUserInfo({
                section: Sections.VARIANT_TYPICALS,
                variant_id: props.variant.id,
              });
              hideMenu();
              navigate(`/variant-typicals/${props.variant.id}`);
            }}
          >
            {props.variant.name}
          </span>
        </div>
        <span>({props.variant.typical_count})</span>
      </div>
      <div>
        <span
          id={styles.add}
          className="bi bi-file-earmark-plus"
          title="Добавить типовую позицию"
          onClick={() => {
            showModal(`create-typical-modal-${props.variant.id}`);
          }}
        ></span>
      </div>
    </div>
  );
};

export default MenuVariantTypical;
