import styles from "./practice.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Complexity } from "../../common/types/complexity";
import { Orientation } from "../../common/types/orientation";
import Board from "../board/board";
import DummyItem from "../items/dummy-item";
import { ChessHelper } from "../../common/utils/chess";
import { Square } from "../board/types/square";
import { Promotions } from "../board/types/promotions";
import { LastMove } from "../board/types/last-move";
import Wrapper from "../shared/wrapper/wrapper";
import Topmenu from "../topmenu/topmenu";
import MenuPractice from "../menu/practice/menu-practice";
import { GetLine } from "../../common/types/get-line";
import { DEFAULT_POSITION, Move } from "chess.js";
import { getLine } from "../../common/api/line/get-line";
import { cloneDeep } from "lodash";
import { editLine } from "../../common/api/line/edit-line";

const Practice = () => {
  const [variantId, setVariantId] = useState(0);
  const [line, setLine] = useState<GetLine | null>(null);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [orientation, setOrientation] = useState(Orientation.WHITE);
  const [lastMove, setLastMove] = useState<LastMove | undefined>(undefined);
  const [shownMoves, setShownMoves] = useState<Move[]>([]);
  const [moves, setMoves] = useState<Move[]>([]);
  const [activeMove, setActiveMove] = useState(-1);

  const wrapperEl = useRef(null);
  const location = useLocation();

  useEffect(() => {
    getVariantLine();
  }, [location.pathname]);

  const getVariantLine = () => {
    const variantId = Number(location.pathname.split("/").pop());

    if (!variantId) {
      return null;
    }

    getLine([variantId]).then((line) => {
      const chess = new ChessHelper();
      chess.loadPgn(line.prev_pgn + line.pgn);

      const moves = chess.history();

      setOrientation(
        line.side === "white" ? Orientation.WHITE : Orientation.BLACK,
      );
      setVariantId(variantId);
      setLine(line);
      setPosition(line.side === "white" ? DEFAULT_POSITION : moves[0].after);
      setLastMove(line.side === "white" ? undefined : moves[0]);
      setShownMoves(line.side === "white" ? [] : [moves[0]]);
      setMoves(moves);
      setActiveMove(line.side === "white" ? -1 : 0);
    });
  };

  const showNewMove = (newActiveMove: number, move?: Move) => {
    setPosition(move?.after || DEFAULT_POSITION);
    setLastMove(
      move ? { from: move.from as Square, to: move.to as Square } : undefined,
    );
    setActiveMove(newActiveMove);
  };

  const moveLeft = () => {
    const newActiveMove = Math.max(-1, activeMove - 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const moveRight = () => {
    const newActiveMove = Math.min(shownMoves.length - 1, activeMove + 1);
    const move = newActiveMove !== -1 ? moves[newActiveMove] : undefined;

    showNewMove(newActiveMove, move);
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    switch (event.code) {
      case "ArrowLeft":
        moveLeft();
        return;
      case "ArrowRight":
        moveRight();
        return;
    }
  };

  const onMove = (
    from: Square,
    to: Square,
    promotion: Promotions,
    finish: () => void,
  ) => {
    if (activeMove === moves.length - 1) {
      finish();
      focus();
      return;
    }

    const nextMove = moves[activeMove + 1];
    const nextPromotion = nextMove.san.slice(-1).toLowerCase();
    if (
      !nextMove ||
      nextMove.from !== from ||
      nextMove.to !== to ||
      (["q", "r", "b", "n"].includes(nextPromotion) &&
        nextPromotion !== promotion)
    ) {
      focus();
      finish();
      return;
    }

    if (activeMove !== shownMoves.length - 1) {
      showNewMove(activeMove + 1, moves[activeMove + 1]);
      focus();
      finish();
      return;
    }

    showNextMove();

    focus();
    finish();
  };

  const showNextMove = () => {
    const activeMove = shownMoves.length - 1;
    const newShownMoves = shownMoves;
    newShownMoves.push(cloneDeep(moves[activeMove + 1]));

    if (activeMove + 1 === moves.length - 1) {
      setShownMoves(newShownMoves);
      showNewMove(activeMove + 1, moves[activeMove + 1]);
      return;
    }

    newShownMoves.push(cloneDeep(moves[activeMove + 2]));

    setShownMoves(newShownMoves);
    showNewMove(activeMove + 2, moves[activeMove + 2]);
  };

  const focus = () => {
    if (wrapperEl.current) {
      const el = wrapperEl.current as any;
      el.focus();
    }
  };

  return (
    <Wrapper
      isMovesOpened={true}
      isFooterOpened={false}
      movesTitle={`Практика`}
      footerTitle={`Анализ`}
      inRef={wrapperEl}
      onKeyDown={(event) => {
        eventHandler(event);
      }}
      topmenu={<Topmenu hiddenMenuButton={false}></Topmenu>}
      menu={<MenuPractice></MenuPractice>}
      board={
        <>
          <Board
            id="chessboard-practice"
            orientation={orientation}
            position={position}
            lastMove={lastMove}
            onMove={(
              from: Square,
              to: Square,
              promotion: Promotions,
              finish: () => void,
            ) => {
              onMove(from, to, promotion, finish);
            }}
          ></Board>
        </>
      }
      buttons={
        <>
          {moves.length === shownMoves.length && line && (
            <>
              <span
                id={styles.anew}
                className={`bi bi-emoji-frown ${styles.practice_button}`}
                title="Снова"
                onClick={() =>
                  editLine(variantId, line.id, Complexity.ANEW).then(() =>
                    getVariantLine(),
                  )
                }
              ></span>
              <span
                id={styles.hard}
                className={`bi bi-emoji-neutral practice-button ${styles.practice_button}`}
                title="Сложно"
                onClick={() =>
                  editLine(variantId, line.id, Complexity.HARD).then(() =>
                    getVariantLine(),
                  )
                }
              ></span>
              <span
                id={styles.good}
                className={`bi bi-emoji-smile practice-button ${styles.practice_button}`}
                title="Хорошо"
                onClick={() =>
                  editLine(variantId, line.id, Complexity.NORMAL).then(() =>
                    getVariantLine(),
                  )
                }
              ></span>
              <span
                id={styles.easy}
                className={`bi bi-emoji-kiss ${styles.practice_button}`}
                title="Супер"
                onClick={() =>
                  editLine(variantId, line.id, Complexity.EASY).then(() =>
                    getVariantLine(),
                  )
                }
              ></span>
            </>
          )}
          {moves.length !== shownMoves.length && (
            <>
              <span
                id={styles.show_next}
                className={`bi bi-skip-end ${styles.practice_button}`}
                title="Показать следующий ход"
                onClick={() => {
                  showNextMove();
                }}
              ></span>
              <span
                id={styles.show_all}
                className={`bi bi-skip-forward ${styles.practice_button}`}
                title="Показать всю линию"
                onClick={() => {
                  const activeMove = moves.length - 1;

                  setShownMoves(cloneDeep(moves));
                  showNewMove(activeMove, moves[activeMove]);
                }}
              ></span>
            </>
          )}
        </>
      }
      movesHeader={
        line && (
          <span
            className={styles.move_header}
          >{`${line.opening_name} - ${line.variant_name}`}</span>
        )
      }
      movesPgn={
        <>
          {shownMoves.map((move: Move, moveId: number) => {
            return (
              <DummyItem
                key={`dummy-item-${moveId}`}
                move={move}
                moveNumber={
                  moves[0].color === "w"
                    ? Math.ceil(moveId / 2) + 1
                    : Math.floor((moveId + 1) / 2) + 1
                }
                isFirstLineMove={moveId === 0}
                isActive={moveId === activeMove}
                onClick={() => {
                  showNewMove(moveId, moves[moveId]);
                }}
              ></DummyItem>
            );
          })}
        </>
      }
      footer={null}
    ></Wrapper>
  );
};

export default Practice;
