import React from "react";
import { GetOpeningTypical } from "../../../common/types/get-opening-typical";
import { GetVariantTypical } from "../../../common/types/get-variant-typical";
import { Orientation } from "../../../common/types/orientation";
import MenuVariantTypical from "./menu-variant-typical";
import styles from "./menu-opening-typical.module.scss";

type MenuOpeningTypicalProps = {
  opening: GetOpeningTypical;
  isOpened: boolean;
  toggleOpening: () => void;
  updateMenu: () => void;
};

const MenuOpeningTypical = (props: MenuOpeningTypicalProps) => {
  const isWhite = props.opening.side === Orientation.WHITE;

  return (
    <div className={styles.item}>
      <div className={styles.item_info} onClick={props.toggleOpening}>
        <div className={styles.item_name}>
          <span
            className={`bi bi-${
              isWhite ? "circle-fill" : "circle"
            } ${styles.opening_side}`}
            title={isWhite ? "За белых" : "За черных"}
          ></span>
          <div className={styles.opening_name}>
            <span>{props.opening.name}</span>
          </div>
        </div>
        <span
          className={`bi bi-${props.isOpened ? "chevron-down" : "chevron-left"}`}
          title={props.isOpened ? "Закрыть варианты" : "Открыть варианты"}
        ></span>
      </div>
      {props.isOpened && (
        <div className={styles.variant_items}>
          {props.opening.variants.map((variant: GetVariantTypical) => {
            return (
              <MenuVariantTypical
                key={`variant-item-${variant.id}`}
                variant={variant}
                updateMenu={props.updateMenu}
              ></MenuVariantTypical>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuOpeningTypical;
