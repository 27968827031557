import React from "react";
import Modal from ".";
import { GetTechnical } from "../../common/types/get-technical";
import { deleteTechnical } from "../../common/api/technical/delete-technical";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteTechnicalModalProps = {
  id: string;
  technical: GetTechnical;
  onClose: () => void;
};

const DeleteTechnicalModal = (props: DeleteTechnicalModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить позицию - ${props.technical.name}`}
      onCancel={() => {
        hideModal(props.id);
      }}
      onOk={() => {
        deleteTechnical(props.technical.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Позиция успешно удалена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить позицию");
          });
      }}
    >
      <span>Вы точно хотите удалить позицию?</span>
    </Modal>
  );
};

export default DeleteTechnicalModal;
