import { GetTypical } from "../../types/get-typical";
import { getAxiosInstance } from "../axios-instance";

export async function createTypical(
  variantId: number,
  name: string,
  pgn: string,
): Promise<GetTypical> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ typical: GetTypical }>(`/api/v1/typicals/variants/${variantId}`, {
        name,
        pgn,
      })
      .then((data) => resolve(data.data.typical))
      .catch((error) => reject(error));
  });
}
