import React from "react";
import Modal from ".";
import { GetTypical } from "../../common/types/get-typical";
import { deleteTypical } from "../../common/api/typical/delete-typical";
import { showDangerAlert, showSuccessAlert } from "../../stores/alert";
import { hideModal } from "../../stores/modal";

type DeleteTypicalModalProps = {
  id: string;
  typical: GetTypical;
  onClose: () => void;
};

const DeleteTypicalModal = (props: DeleteTypicalModalProps) => {
  return (
    <Modal
      id={props.id}
      cancelName="Не удалять"
      okName="Удалить"
      title={`Удалить позицию - ${props.typical.name}`}
      onCancel={() => hideModal(props.id)}
      onOk={() => {
        deleteTypical(props.typical.id)
          .then(() => {
            props.onClose();
            hideModal(props.id);
            showSuccessAlert("Позиция успешно удалена");
          })
          .catch(() => {
            hideModal(props.id);
            showDangerAlert("Не удалось удалить позицию");
          });
      }}
    >
      <span>Вы точно хотите удалить позицию?</span>
    </Modal>
  );
};

export default DeleteTypicalModal;
