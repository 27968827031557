import { GetOpeningAnalyze } from "../../types/get-opening-analyze";
import { Orientation } from "../../types/orientation";
import { getAxiosInstance } from "../axios-instance";

export async function createOpening(
  side: Orientation,
  name: string,
): Promise<GetOpeningAnalyze> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ opening: GetOpeningAnalyze }>(`/api/v1/openings`, {
        side,
        name,
      })
      .then((data) => resolve(data.data.opening))
      .catch((error) => reject(error));
  });
}
