import { GetVariantAnalyze } from "../../types/get-variant-analyze";
import { getAxiosInstance } from "../axios-instance";

export async function createVariant(
  openingId: number,
  name: string,
): Promise<GetVariantAnalyze> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post<{ variant: GetVariantAnalyze }>(`/api/v1/variants`, {
        opening_id: openingId,
        name,
      })
      .then((data) => resolve(data.data.variant))
      .catch((error) => reject(error));
  });
}
